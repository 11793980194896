import ChannelSelector from "../../Common/ChannelSelector";

const Step1 = (props) => {
  return (
    <div className="w-full border space-x-8 border-gray-200  flex bg-white/50 content-center rounded-lg shadow-sm py-10 px-10 xs:max-w-sm animate-fade">
      <div className="w-1/2 flex  mb-4">
        <img
          src={process.env.PUBLIC_URL + "/elephant.png"}
          alt="Dara"
          className="h-72 w-auto"
        />
      </div>
      <div className="w-3/4 flex-col items-center justify-center align-middle">
        <h1 className="text-2xl font-medium mb-4 mt-6 text-black font-rubik">
          🎉 Great! One more step..
        </h1>
        <p className="text-light">
          The last step is to add Dara to channels you want to monitor for
          non-inclusive language.
        </p>
        <ChannelSelector />
      </div>
    </div>
  );
};

export default Step1;
