import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import {
  ExclamationTriangleIcon,
  ChevronUpDownIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import * as api from "../../services/api";
import { SmalLoadingIndicator } from "../Helpers/LoadingComponents";
import { incidentCategories } from "../Helpers/Helpers";

const IncidentHandlerModal = (props) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([incidentCategories[0]]);

  const closeModal = () => {
    setSuccess(false);
    props.closeModal();
    setSelected([incidentCategories[0]]);
  };

  const user = props.user;

  useEffect(() => {
    if (user.responsible_for_categories) {
      // Match the user's responsible categories with the incident categories and set the selected categories, match by name
      // Both are arrays of objects with name and description
      const selectedCategories = incidentCategories.filter((category) => {
        return user.responsible_for_categories.some(
          (userCategory) => userCategory.name === category.name
        );
      });
      setSelected(selectedCategories);
    }
  }, [user]);

  const updateIncidentHandler = async () => {
    setLoading(true);
    const res = await api.updateUserRoles(
      "SET_AS_INCIDENT_HANDLER",
      user.user_id,
      selected
    );

    if (res) {
      setLoading(false);
      setSuccess(true);
    } else {
      setLoading(false);
      setSuccess(false);
    }
  };

  const removeIncidentHandler = async () => {
    setLoading(true);
    const res = await api.updateUserRoles(
      "REMOVE_AS_INCIDENT_HANDLER",
      user.user_id
    );

    if (res) {
      setLoading(false);
      setSuccess(true);
    } else {
      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 overflow-visible"
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-[2px] overflow-visible" />
        </Transition.Child>

        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center overflow-visible">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl overflow-visible transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {!success && !loading && (
                  <Dialog.Title
                    as="div"
                    className="text-lg font-medium items-center flex space-x-3 text-"
                  >
                    <div className="flex items-center justify-center h-8 w-8 rounded-lg bg-primaryColor border border-primaryColor bg-opacity-10">
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-primaryColor"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="text-primaryColor">
                      Manage incident handler role
                    </div>
                  </Dialog.Title>
                )}
                {!success && !loading && (
                  <>
                    <p className="text-sm mt-3 text-gray-700">
                      Select the incident categories which this user will be
                      handling. This will give the user access to the incident
                      handler dashboard and allow them to receive notifications
                      for incidents of this category.
                    </p>
                    <form className="mt-6">
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="categories"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Select responsible categories
                        </label>
                        <div className="mt-2 flex w-full rounded-lg shadow-sm">
                          <Listbox
                            value={selected}
                            onChange={setSelected}
                            multiple
                          >
                            <div className="relative z-20 mt-1 w-full h-full">
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute mt-1 w-full z-50 overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {incidentCategories.map(
                                    (category, categoryIdx) => (
                                      <Listbox.Option
                                        key={categoryIdx}
                                        className={({ active }) =>
                                          `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                            active
                                              ? "bg-primaryColor/10 text-primaryColor"
                                              : "text-gray-900"
                                          }`
                                        }
                                        value={category}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${
                                                selected
                                                  ? "font-medium"
                                                  : "font-normal"
                                              }`}
                                            >
                                              {category.name}
                                            </span>
                                            {selected ? (
                                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primaryColor">
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    )
                                  )}
                                </Listbox.Options>
                              </Transition>
                              <Listbox.Button className="relative w-full cursor-default rounded-lg border  border-gray-300 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-primaryColor focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryColor sm:text-sm">
                                <div className="divide-y divide-gray-200">
                                  {selected.map((category) => {
                                    return (
                                      <div className="py-2">
                                        <span className="flex items-center">
                                          {category.name}
                                        </span>
                                        <p className="text-gray-500 text-xs mt-1">
                                          {category.description}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                                {selected.length === 0 && (
                                  <span className="text-gray-500">
                                    Select categories
                                  </span>
                                )}
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                            </div>
                          </Listbox>
                        </div>
                      </div>
                    </form>
                    <div className="mt-6 space-x-3 flex items-center justify-end">
                      <button
                        type="button"
                        className="focus:outline-none inline-flex  float-right justify-center rounded-lg border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-grey-900 hover:bg-grey-200 focus-visible:ring-2 focus-visible:ring-grey-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={async () => {
                          await removeIncidentHandler();
                        }}
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-red-600 transition-all hover:bg-red/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor"
                      >
                        Remove as handler
                      </button>
                      <button
                        onClick={async () => {
                          await updateIncidentHandler();
                        }}
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-black transition-all hover:bg-black/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor"
                      >
                        Update categories
                      </button>
                    </div>
                  </>
                )}
                {loading && (
                  <div className="flex items-center w-full h-48 justify-center animate-enter">
                    <SmalLoadingIndicator />
                  </div>
                )}
                {success && (
                  <>
                    <div className="flex items-center mt-8 justify-center animate-enter">
                      <ExclamationTriangleIcon
                        className="h-24 w-24 text-green-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-1 text-center items-center content-center flex flex-col">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-medium text-green-500"
                      >
                        User role updated
                      </Dialog.Title>
                      <div className="mt-2 max-w-sm">
                        <p className="text-sm text-gray-500">
                          The user incident handler role has been updated
                          successfully.
                        </p>
                      </div>
                    </div>
                    <div className="mt-6 space-x-3 flex items-center justify-end">
                      <button
                        type="button"
                        className="focus:outline-none inline-flex  float-right justify-center rounded-lg border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-grey-900 hover:bg-grey-200 focus-visible:ring-2 focus-visible:ring-grey-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default IncidentHandlerModal;
