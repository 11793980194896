import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useWizard } from "react-use-wizard";
import * as api from "../../../services/api";
import { state, useSnapshot } from "../../../state/stateStore";

const Step2 = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { nextStep } = useWizard();

  const snap = useSnapshot(state);
  const workspace = snap.workspace;

  useEffect(() => {
    reset({
      org_name: workspace.workspace.name,
    });
  }, [workspace]);

  const onSubmit = async (data) => {
    // Submit questionnaire data to backend
    data.is_org_step = true;
    await api.sendQuestionnaire(data);
    nextStep();
  };

  return (
    <div className="w-full border space-x-8 border-gray-200 flex bg-white/60 content-center rounded-lg shadow-sm py-8 px-10 xs:max-w-sm animate-fade">
      <div className="w-3/4 flex-col items-center justify-center align-middle">
        <form id="org-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-xl font-medium mb-4 text-black font-rubik">
            Organization info
          </h2>
          <div className="mt-4 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="word"
                className="block text-sm font-medium text-gray-700"
              >
                Your organization's name
              </label>
              <div className="mt-2 flex w-full rounded-lg shadow-sm">
                <input
                  type="text"
                  name="org_name"
                  id="org_name"
                  {...register("org_name", { required: true })}
                  required
                  placeholder="Enter your organization's name"
                  className="flex-1 focus:ring-primaryColor focus:border-primaryColor block w-full min-w-0 rounded-lg sm:text-sm border-gray-300"
                />
              </div>
              {errors.org_name && (
                <span className="text-sm mt-1 text-red-500">
                  This field is required
                </span>
              )}
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="word"
                className="block text-sm font-medium text-gray-700"
              >
                Your organization's size
              </label>
              <div className="mt-2 flex w-full rounded-lg shadow-sm">
                <select
                  id="org_size"
                  name="org_size"
                  {...register("org_size", { required: true })}
                  className="flex-1 focus:ring-primaryColor focus:border-primaryColor block w-full min-w-0 rounded-lg sm:text-sm border-gray-300"
                >
                  <option value="1-49">1-49</option>
                  <option value="50-199"> 50-199</option>
                  <option value="200-499">200-499</option>
                  <option value="500-999">500-999</option>
                  <option value="1000-2000">1000-2000</option>
                  <option value="2000-9999">2000-9999</option>
                  <option value="10000+">10000+</option>
                </select>
              </div>
              {errors.org_size && (
                <span className="text-sm mt-1 text-red-500">
                  This field is required
                </span>
              )}
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="word"
                className="block text-sm font-medium text-gray-700"
              >
                Your organization's industry
              </label>
              <div className="mt-2 flex w-full rounded-lg shadow-sm">
                <select
                  id="org_industry"
                  name="org_industry"
                  {...register("org_industry", { required: true })}
                  className="flex-1 focus:ring-primaryColor focus:border-primaryColor block w-full min-w-0 rounded-lg sm:text-sm border-gray-300"
                >
                  <option value="Accounting">Accounting</option>
                  <option value="Airlines/Aviation">Airlines/Aviation</option>
                  <option value="Alternative Dispute Resolution">
                    Alternative Dispute Resolution
                  </option>
                  <option value="Alternative Medicine">
                    Alternative Medicine
                  </option>
                  <option value="Animation">Animation</option>
                  <option value="Apparel/Fashion">Apparel/Fashion</option>
                  <option value="Architecture/Planning">
                    Architecture/Planning
                  </option>
                  <option value="Arts/Crafts">Arts/Crafts</option>
                  <option value="Automotive">Automotive</option>
                  <option value="Aviation/Aerospace">Aviation/Aerospace</option>
                  <option value="Banking/Mortgage">Banking/Mortgage</option>
                  <option value="Biotechnology/Greentech">
                    Biotechnology/Greentech
                  </option>
                  <option value="Broadcast Media">Broadcast Media</option>
                  <option value="Building Materials">Building Materials</option>
                  <option value="Business Supplies/Equipment">
                    Business Supplies/Equipment
                  </option>
                  <option value="Capital Markets/Hedge Fund/Private Equity">
                    Capital Markets/Hedge Fund/Private Equity
                  </option>
                  <option value="Chemicals">Chemicals</option>
                  <option value="Civic/Social Organization">
                    Civic/Social Organization
                  </option>
                  <option value="Civil Engineering">Civil Engineering</option>
                  <option value="Commercial Real Estate">
                    Commercial Real Estate
                  </option>
                  <option value="Computer Games">Computer Games</option>
                  <option value="Computer Hardware">Computer Hardware</option>
                  <option value="Computer Networking">
                    Computer Networking
                  </option>
                  <option value="Computer Software/Engineering">
                    Computer Software/Engineering
                  </option>
                  <option value="Computer/Network Security">
                    Computer/Network Security
                  </option>
                  <option value="Construction">Construction</option>
                  <option value="Consumer Electronics">
                    Consumer Electronics
                  </option>
                  <option value="Consumer Goods">Consumer Goods</option>
                  <option value="Consumer Services">Consumer Services</option>
                  <option value="Cosmetics">Cosmetics</option>
                  <option value="Dairy">Dairy</option>
                  <option value="Defense/Space">Defense/Space</option>
                  <option value="Design">Design</option>
                  <option value="E-Learning">E-Learning</option>
                  <option value="Education Management">
                    Education Management
                  </option>
                  <option value="Electrical/Electronic Manufacturing">
                    Electrical/Electronic Manufacturing
                  </option>
                  <option value="Entertainment/Movie Production">
                    Entertainment/Movie Production
                  </option>
                  <option value="Environmental Services">
                    Environmental Services
                  </option>
                  <option value="Events Services">Events Services</option>
                  <option value="Executive Office">Executive Office</option>
                  <option value="Facilities Services">
                    Facilities Services
                  </option>
                  <option value="Farming">Farming</option>
                  <option value="Financial Services">Financial Services</option>
                  <option value="Fine Art">Fine Art</option>
                  <option value="Fishery">Fishery</option>
                  <option value="Food Production">Food Production</option>
                  <option value="Food/Beverages">Food/Beverages</option>
                  <option value="Fundraising">Fundraising</option>
                  <option value="Furniture">Furniture</option>
                  <option value="Gambling/Casinos">Gambling/Casinos</option>
                  <option value="Glass/Ceramics/Concrete">
                    Glass/Ceramics/Concrete
                  </option>
                  <option value="Government Administration">
                    Government Administration
                  </option>
                  <option value="Government Relations">
                    Government Relations
                  </option>
                  <option value="Graphic Design/Web Design">
                    Graphic Design/Web Design
                  </option>
                  <option value="Health/Fitness">Health/Fitness</option>
                  <option value="Higher Education/Acadamia">
                    Higher Education/Acadamia
                  </option>
                  <option value="Hospital/Health Care">
                    Hospital/Health Care
                  </option>
                  <option value="Hospitality">Hospitality</option>
                  <option value="Human Resources/HR">Human Resources/HR</option>
                  <option value="Import/Export">Import/Export</option>
                  <option value="Individual/Family Services">
                    Individual/Family Services
                  </option>
                  <option value="Industrial Automation">
                    Industrial Automation
                  </option>
                  <option value="Information Services">
                    Information Services
                  </option>
                  <option value="Information Technology/IT">
                    Information Technology/IT
                  </option>
                  <option value="Insurance">Insurance</option>
                  <option value="International Affairs">
                    International Affairs
                  </option>
                  <option value="International Trade/Development">
                    International Trade/Development
                  </option>
                  <option value="Internet">Internet</option>
                  <option value="Investment Banking/Venture">
                    Investment Banking/Venture
                  </option>
                  <option value="Investment Management/Hedge Fund/Private Equity">
                    Investment Management/Hedge Fund/Private Equity
                  </option>
                  <option value="Judiciary">Judiciary</option>
                  <option value="Law Enforcement">Law Enforcement</option>
                  <option value="Law Practice/Law Firms">
                    Law Practice/Law Firms
                  </option>
                  <option value="Legal Services">Legal Services</option>
                  <option value="Legislative Office">Legislative Office</option>
                  <option value="Leisure/Travel">Leisure/Travel</option>
                  <option value="Library">Library</option>
                  <option value="Logistics/Procurement">
                    Logistics/Procurement
                  </option>
                  <option value="Luxury Goods/Jewelry">
                    Luxury Goods/Jewelry
                  </option>
                  <option value="Machinery">Machinery</option>
                  <option value="Management Consulting">
                    Management Consulting
                  </option>
                  <option value="Maritime">Maritime</option>
                  <option value="Market Research">Market Research</option>
                  <option value="Marketing/Advertising/Sales">
                    Marketing/Advertising/Sales
                  </option>
                  <option value="Mechanical or Industrial Engineering">
                    Mechanical or Industrial Engineering
                  </option>
                  <option value="Media Production">Media Production</option>
                  <option value="Medical Equipment">Medical Equipment</option>
                  <option value="Medical Practice">Medical Practice</option>
                  <option value="Mental Health Care">Mental Health Care</option>
                  <option value="Military Industry">Military Industry</option>
                  <option value="Mining/Metals">Mining/Metals</option>
                  <option value="Motion Pictures/Film">
                    Motion Pictures/Film
                  </option>
                  <option value="Museums/Institutions">
                    Museums/Institutions
                  </option>
                  <option value="Music">Music</option>
                  <option value="Nanotechnology">Nanotechnology</option>
                  <option value="Newspapers/Journalism">
                    Newspapers/Journalism
                  </option>
                  <option value="Non-Profit/Volunteering">
                    Non-Profit/Volunteering
                  </option>
                  <option value="Oil/Energy/Solar/Greentech">
                    Oil/Energy/Solar/Greentech
                  </option>
                  <option value="Online Publishing">Online Publishing</option>
                  <option value="Other Industry">Other Industry</option>
                  <option value="Outsourcing/Offshoring">
                    Outsourcing/Offshoring
                  </option>
                  <option value="Package/Freight Delivery">
                    Package/Freight Delivery
                  </option>
                  <option value="Packaging/Containers">
                    Packaging/Containers
                  </option>
                  <option value="Paper/Forest Products">
                    Paper/Forest Products
                  </option>
                  <option value="Performing Arts">Performing Arts</option>
                  <option value="Pharmaceuticals">Pharmaceuticals</option>
                  <option value="Philanthropy">Philanthropy</option>
                  <option value="Photography">Photography</option>
                  <option value="Plastics">Plastics</option>
                  <option value="Political Organization">
                    Political Organization
                  </option>
                  <option value="Primary/Secondary Education">
                    Primary/Secondary Education
                  </option>
                  <option value="Printing">Printing</option>
                  <option value="Professional Training">
                    Professional Training
                  </option>
                  <option value="Program Development">
                    Program Development
                  </option>
                  <option value="Public Relations/PR">
                    Public Relations/PR
                  </option>
                  <option value="Public Safety">Public Safety</option>
                  <option value="Publishing Industry">
                    Publishing Industry
                  </option>
                  <option value="Railroad Manufacture">
                    Railroad Manufacture
                  </option>
                  <option value="Ranching">Ranching</option>
                  <option value="Real Estate/Mortgage">
                    Real Estate/Mortgage
                  </option>
                  <option value="Recreational Facilities/Services">
                    Recreational Facilities/Services
                  </option>
                  <option value="Religious Institutions">
                    Religious Institutions
                  </option>
                  <option value="Renewables/Environment">
                    Renewables/Environment
                  </option>
                  <option value="Research Industry">Research Industry</option>
                  <option value="Restaurants">Restaurants</option>
                  <option value="Retail Industry">Retail Industry</option>
                  <option value="Security/Investigations">
                    Security/Investigations
                  </option>
                  <option value="Semiconductors">Semiconductors</option>
                  <option value="Shipbuilding">Shipbuilding</option>
                  <option value="Sporting Goods">Sporting Goods</option>
                  <option value="Sports">Sports</option>
                  <option value="Staffing/Recruiting">
                    Staffing/Recruiting
                  </option>
                  <option value="Supermarkets">Supermarkets</option>
                  <option value="Telecommunications">Telecommunications</option>
                  <option value="Textiles">Textiles</option>
                  <option value="Think Tanks">Think Tanks</option>
                  <option value="Tobacco">Tobacco</option>
                  <option value="Translation/Localization">
                    Translation/Localization
                  </option>
                  <option value="Transportation">Transportation</option>
                  <option value="Utilities">Utilities</option>
                  <option value="Venture Capital/VC">Venture Capital/VC</option>
                  <option value="Veterinary">Veterinary</option>
                  <option value="Warehousing">Warehousing</option>
                  <option value="Wholesale">Wholesale</option>
                  <option value="Wine/Spirits">Wine/Spirits</option>
                  <option value="Wireless">Wireless</option>
                  <option value="Writing/Editing">Writing/Editing</option>
                </select>
              </div>
              {errors.org_industry && (
                <span className="text-sm mt-1 text-red-500">
                  This field is required
                </span>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="w-1/2 flex  mb-4">
        <img
          src={process.env.PUBLIC_URL + "/elephant2.png"}
          alt="Dara"
          className="h-72 w-auto"
        />
      </div>
    </div>
  );
};

export default Step2;
