import React from "react";

const Login = () => {
  const handleLoginWithSlack = () => {
    const authUrl = process.env.REACT_APP_BACKEND_URL + "/auth/slack";
    window.open(authUrl, "_self");
  };

  return (
    <div className="flex h-full  bg-gradient-to-b from-white via-primaryColor/20 to-primaryColor/40 text-black">
      <div className="flex flex-1 flex-col h-full justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto flex flex-col w-full max-w-sm h-full">
          <div className="flex flex-col grow justify-items-center justify-center">
            <div>
              <div className="flex items-center space-x-4">
                <img
                  className="h-16 w-auto shadow-lg border border-primaryColor/50 rounded-xl"
                  src={process.env.PUBLIC_URL + "/favicon.png"}
                  alt="Dara"
                />
                <div className="text-5xl font-rubik font-semibold text-black">
                  Dara
                </div>
              </div>
              <h2 className="mt-6 text-3xl font-semibold font-rubik text-black">
                Imagine a workspace where everyone feels included.
              </h2>
            </div>
            <p className="font-normal mt-4">
              Use your Slack account to access the Dara dashboard. If you don't
              have an account yet, you can start your{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={process.env.REACT_APP_INSTALL_URL}
                className="font-medium cursor-pointer text-primaryGreen hover:text-primaryGreen hover:underline"
              >
                freemium subscription
              </a>
              .
            </p>
            <div className="flex-col items-center space-y-4 mt-8">
              <div
                className="bg-black text-white group px-6 py-4 hover:shadow-sm rounded-lg justify-center hover:scale-105 transition-all duration-300 flex align-middle items-center font-semibold text-xl cursor-pointer"
                onClick={() => handleLoginWithSlack()}
              >
                <img
                  className="h-8 w-8 mr-3 group-hover:rotate-180 transition-all duration-300"
                  src={process.env.PUBLIC_URL + "/slack-logo.svg"}
                  alt="Slack"
                />
                <p>Sign in with Slack</p>
              </div>
            </div>
            <p className="text-gray-700 mt-8 text-sm text-center">
              By signing in, you agree to our{" "}
              <a
                href="https://www.darabot.com/terms-of-service"
                target="_blank"
                className="hover:underline cursor-pointer"
                rel="noreferrer"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.darabot.com/privacy-policy"
                target="_blank"
                className="hover:underline cursor-pointer"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
          <a
            href="https://www.darabot.com/contact"
            className="mt-6 text-gray-700 text-center text-xs flex-shrink-0 hover:underline hover:text-primaryGreen cursor-pointer"
          >
            Trouble signing in?
          </a>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover object-right-bottom"
          src={process.env.PUBLIC_URL + "/diversity.jpg"}
          alt="Diversity"
        />
      </div>
    </div>
  );
};

export default Login;
