import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { getUser, getWorkspace } from "./state/stateStore";
import ScrollToTop from "./components/Helpers/ScrollToTop";
import ProtectedRoute from "./auth/ProtectedRoute";

// Import pages / components
import Login from "./components/Login";
import NotInstalled from "./components/NotInstalled";
import Dashboard from "./components/Dashboard/Dashboard";
import Dictionary from "./components/Dictionary/Dictionary";
import Education from "./components/Education/Education";
import Settings from "./components/Settings/Settings";
import Account from "./components/Account/Account";
import ManageUsers from "./components/Account/ManageUsers";
import Reporting from "./components/Reporting/Reporting";
import ProfilePage from "./components/Reporting/ProfilePage";
import Incidents from "./components/Incidents/Incidents";
import IncidentPage from "./components/Incidents/IncidentPage";
import WizardBase from "./components/Wizard/WizardBase";

const App = () => {
  useEffect(() => {
    const fetchData = async () => {
      await getUser();
      await getWorkspace();
    };
    fetchData();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/notinst" element={<NotInstalled />} />
        <Route
          index
          path="/dashboard"
          element={
            <ProtectedRoute checkHandler={true}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reporting"
          element={
            <ProtectedRoute checkAdmin={true}>
              <Reporting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reporting/profile/:userId"
          element={
            <ProtectedRoute checkAdmin={true}>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dictionary"
          element={
            <ProtectedRoute checkAdmin={true}>
              <Dictionary />
            </ProtectedRoute>
          }
        />
        <Route
          path="/incidents"
          element={
            <ProtectedRoute checkHandler={true}>
              <Incidents />
            </ProtectedRoute>
          }
        />
        <Route
          path="/incidents/:incidentId"
          element={
            <ProtectedRoute>
              <IncidentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/education"
          element={
            <ProtectedRoute checkAdmin={true}>
              <Education />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute checkAdmin={true}>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account/users/manage"
          element={
            <ProtectedRoute checkAdmin={true}>
              <ManageUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wizard"
          element={
            <ProtectedRoute wizard={true}>
              <WizardBase />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
      <Toaster position="top-right" />
    </BrowserRouter>
  );
};

export default App;
