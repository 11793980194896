import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import * as api from "../../services/api";
import { SmalLoadingIndicator } from "../Helpers/LoadingComponents";

const ResolveIncidentModal = (props) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resolution, setResolution] = useState("");

  const closeModal = () => {
    setResolution("");
    props.setOpen(false);
  };

  const resolveIncident = async () => {
    if (!resolution || resolution.length === 0) {
      alert("Please provide a resolution report.");
      return;
    }
    setLoading(true);
    const res = await api.resolveIncident(props.incident._id, resolution);
    if (res) {
      setLoading(false);
      setSuccess(true);
    } else {
      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-[2px]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {!success && (
                  <Dialog.Title
                    as="div"
                    className="text-lg font-medium items-center flex space-x-3 text-"
                  >
                    <div className="flex items-center justify-center h-8 w-8 rounded-lg bg-green-600 border border-green-600 bg-opacity-10">
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="text-green-600">Resolve incident</div>
                  </Dialog.Title>
                )}
                {!success && !loading && (
                  <>
                    <p className="text-sm mt-3 text-gray-700">
                      To resolve this incident, you must provide a report of
                      actions for the resolution. This will be visible to the
                      reporter.
                    </p>

                    <form>
                      <div className="mt-4">
                        <label
                          htmlFor="resolution"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Resolution
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="resolution"
                            name="resolution"
                            placeholder="Enter a resolution for this incident.."
                            rows={3}
                            className="shadow-sm focus:ring-green-600 transition-all focus:border-green-600 block w-full sm:text-sm border-gray-300 rounded-md"
                            defaultValue={""}
                            onChange={(e) => setResolution(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </form>
                    <div className="mt-6 space-x-3 flex items-center justify-end">
                      <button
                        type="button"
                        className="focus:outline-none inline-flex  float-right justify-center rounded-lg border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-grey-900 hover:bg-grey-200 focus-visible:ring-2 focus-visible:ring-grey-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                        onClick={() => resolveIncident()}
                      >
                        Resolve
                      </button>
                    </div>
                  </>
                )}
                {loading && (
                  <div className="flex items-center w-full h-48 justify-center animate-enter">
                    <SmalLoadingIndicator />
                  </div>
                )}
                {success && !loading && (
                  <>
                    <div className="flex items-center mt-8 justify-center animate-enter">
                      <CheckCircleIcon
                        className="h-24 w-24 text-green-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-2 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-medium text-green-500"
                      >
                        Incident resolved
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          The incident has been resolved and the user has been
                          notified.
                        </p>
                      </div>
                    </div>
                    <div className="mt-6 space-x-3 flex items-center justify-end">
                      <button
                        type="button"
                        className="focus:outline-none inline-flex  float-right justify-center rounded-lg border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-grey-900 hover:bg-grey-200 focus-visible:ring-2 focus-visible:ring-grey-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ResolveIncidentModal;
