import { useEffect, useState, useMemo, Fragment } from "react";
import { state, useSnapshot } from "../../state/stateStore";
import { RadioGroup, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentPlusIcon,
  MinusSmallIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { useTable, usePagination, useSortBy } from "react-table";
import AddEditModal from "./AddEditWordDialog";
import * as api from "../../services/api";
import { categories } from "../Helpers/Helpers";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const WordsTable = ({
  columns,
  data,
  setShowAddEditModal,
  setActiveWord,
  getDictionary,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      autoResetFilters: false,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: [
          "_id",
          "response",
          "editable",
          "alternative_words",
          "plural",
        ],
        sortBy: [
          {
            id: "word",
            desc: false,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="mt-2 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle  sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table
                {...getTableProps()}
                className="min-w-full divide-y  border border-gray-300 rounded-lg divide-gray-300"
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="divide-x divide-gray-300"
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-6 py-3.5 text-left text-sm font-semibold"
                        >
                          <div className="group inline-flex">
                            {column.render("Header")}
                            {!column.isSorted && (
                              <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                <MinusSmallIcon
                                  className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronUpIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ))}
                      <th className="px-6 py-3.5 text-left text-sm font-medium text-gray-800"></th>
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        key={i}
                        className="divide-x divide-gray-200"
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                              key={i}
                            >
                              <div className="text-gray-700 flex items-center">
                                {cell.render("Cell")}
                              </div>
                            </td>
                          );
                        })}
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a
                            className="text-primaryColor hover:text-indigo-900 cursor-pointer"
                            onClick={() => {
                              setActiveWord(row.values);
                              setShowAddEditModal(true);
                            }}
                          >
                            Edit
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="py-3 flex items-center justify-between rounded-lg mt-4">
              <div className="ml-2 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                  </p>
                </div>
                <div className="ml-10 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  {/* <select
                    className="relative py-2 pl-3 pr-10 text-left bg-white rounded-lg border-none shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 sm:text-xs"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 30].map((pageSize) => (
                      <category key={pageSize} value={pageSize}>
                        Show {pageSize} per page
                      </category>
                    ))}
                  </select> */}
                </div>
                <div>
                  <nav
                    className="relative z-0 inline-flex rounded-lg shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>

                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Dictionary = () => {
  const [currentDictionary, setCurrentDictionary] = useState([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [activeWord, setActiveWord] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [search, setSearch] = useState("");
  const [loadingRow, setLoadingRow] = useState(null);

  const getDictionary = async () => {
    let data = await api.getDictionary();
    if (data.dictionary) {
      setCurrentDictionary(data.dictionary);
    } else {
      setCurrentDictionary([]);
    }
  };

  const handleSearch = (term) => {
    setSearch(term);
  };

  useEffect(() => {
    getDictionary();
  }, []);

  useEffect(() => {
    getDictionary();
  }, [showAddEditModal]);

  const enableDisableWord = async (wordId, state) => {
    let wordData = {};
    wordData.wordId = wordId;
    wordData.enabled = state;
    const res = await api.editWord(wordData);
    if (res.success) {
      getDictionary();
    }
  };

  const columns = [
    {
      Header: "Id",
      accessor: "_id",
    },
    {
      Header: "Word",
      accessor: "word",
      Cell: (props) => {
        return (
          <span
            className="text-gray-900 font-medium hover:text-primaryColor cursor-pointer"
            onClick={() => {
              setActiveWord(props.row.original);
              setShowAddEditModal(true);
            }}
          >
            {props.value}
          </span>
        );
      },
    },
    { Header: "Plural", accessor: "plural" },
    {
      Header: "Definition",
      accessor: "definition",
      Cell: (props) => {
        return (
          <span className="text-gray-900">
            {props.value.length > 80
              ? props.value.substring(0, 50) + "..."
              : props.value}
          </span>
        );
      },
    },
    {
      Header: "Categories",
      accessor: "categories",
      Cell: (props) => {
        return props.value.map((category, i) => (
          <span
            className="inline-flex items-center px-2.5 py-1 rounded-lg text-xs font-medium bg-primaryColor/10 text-primaryColor mr-1.5"
            key={i}
          >
            {category}
          </span>
        ));
      },
    },
    {
      Header: "Enabled",
      accessor: "enabled",
      Cell: (props) => {
        const wordId = props.row.original._id;
        return (
          <div>
            {loadingRow === wordId && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium bg-gray-100 text-gray-800">
                Loading..
              </span>
            )}

            {!loadingRow && (
              <>
                {props.value ? (
                  <span
                    onClick={async () => {
                      setLoadingRow(wordId);
                      await enableDisableWord(wordId, false);
                      setLoadingRow(null);
                    }}
                    disabled={loadingRow === wordId}
                    className="inline-flex cursor-pointer items-center px-2.5 py-0.5 rounded-lg text-xs font-medium bg-green-100 text-green-800"
                  >
                    Enabled
                  </span>
                ) : (
                  <span
                    onClick={async () => {
                      setLoadingRow(wordId);
                      await enableDisableWord(wordId, true);
                      setLoadingRow(null);
                    }}
                    disabled={loadingRow === wordId}
                    className="inline-flex cursor-pointer items-center px-2.5 py-0.5 rounded-lg text-xs font-medium bg-red-100 text-red-800"
                  >
                    Disabled
                  </span>
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      Header: "Response",
      accessor: "response",
    },
    {
      Header: "Editable",
      accessor: "editable",
    },
    {
      Header: "alternative_words",
      accessor: "alternative_words",
    },
  ];

  let filteredData = [];
  let enabledCount = 0;
  let disabledCount = 0;

  // Filter data by search term
  if (currentDictionary && currentDictionary.words) {
    filteredData = currentDictionary.words.filter((item) => {
      return item.word.toLowerCase().includes(search.toLowerCase());
    });

    // Calculate word cout per category and add to category object
    categories.forEach((category) => {
      let count = 0;
      currentDictionary.words.forEach((word) => {
        if (word.categories.includes(category.name)) {
          count++;
        }
      });
      category.count = count;
    });

    // Calculate word amounts that are enabled and disabled per category and add to category object
    categories.forEach((category) => {
      let enabledCount = 0;
      let disabledCount = 0;
      currentDictionary.words.forEach((word) => {
        if (word.categories.includes(category.name)) {
          if (word.enabled) {
            enabledCount++;
          } else {
            disabledCount++;
          }
        }
      });
      category.enabledCount = enabledCount;
      category.disabledCount = disabledCount;
    });

    // Calculate all disabled words and enabled words

    currentDictionary.words.forEach((word) => {
      if (word.enabled) {
        enabledCount++;
      } else {
        disabledCount++;
      }
    });
  }

  // Filter data by category
  if (selectedCategory) {
    filteredData = filteredData.filter((item) => {
      return item.categories.includes(selectedCategory.name);
    });
  }

  const snap = useSnapshot(state);
  const workspace = snap.workspace;

  return (
    <>
      <AddEditModal
        open={showAddEditModal}
        closeModal={() => {
          setActiveWord(null);
          setShowAddEditModal(false);
        }}
        word={activeWord}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="mt-8">
          <div className="flex items-center">
            <div className="flex-auto">
              <h1 className="text-2xl text-primaryColor font-rubik font-semibold">
                Dictionary
              </h1>
              <p className="text-gray-600 mt-1 text-md">
                Mange words that Dara detects
              </p>
            </div>
            {workspace.subscription.plan !== "freemium" && (
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <div
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-lg cursor-pointer bg-black px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryColor"
                  onClick={() => setShowAddEditModal(true)}
                >
                  <DocumentPlusIcon
                    className="-ml-0.5 h-4 w-4"
                    aria-hidden="true"
                  />
                  Add word
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="animate-fade mt-10 mb-10">
          <label className="block text-sm font-medium mb-1 text-gray-500">
            Select category
          </label>
          <div className="flex justify-between w-full">
            <RadioGroup
              value={selectedCategory}
              onChange={setSelectedCategory}
              className="mt-2"
            >
              <RadioGroup.Label className="sr-only">
                Choose category
              </RadioGroup.Label>
              <div className="grid grid-cols-3 gap-3 sm:grid-cols-4">
                <RadioGroup.Option
                  key="all"
                  value={null}
                  className={({ checked, active }) =>
                    classNames(
                      checked ? "border-transparent" : "border-gray-300",
                      active
                        ? "border-primaryColor ring-2 ring-primaryColor"
                        : "",
                      "relative flex cursor-pointer rounded-lg border transition-all duration-200 bg-white/50 backdrop-blur p-4 shadow-sm focus:outline-none"
                    )
                  }
                >
                  {({ checked, active }) => (
                    <>
                      <span className="flex flex-1">
                        <span className="flex flex-col">
                          <div className="flex-grow flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className="block text-sm font-medium text-gray-900"
                            >
                              All words
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className="mt-1 flex items-center text-xs text-gray-500"
                            >
                              Show all words in the dictionary
                            </RadioGroup.Description>
                          </div>
                          <div className="flex-shrink-0">
                            <RadioGroup.Description
                              as="span"
                              className="text-xs font-medium text-gray-800"
                            >
                              {currentDictionary &&
                                currentDictionary.words &&
                                currentDictionary.words.length}{" "}
                              words total
                            </RadioGroup.Description>
                            <div className="flex items-center space-x-4 mt-2">
                              <div className="text-xs flex items-center text-gray-500">
                                <div className="w-3 h-3 flex items-center justify-center content-center bg-green-200/80 rounded-full mr-1">
                                  <div className="w-2 h-2 bg-green-500 rounded-full" />
                                </div>
                                Enabled: {enabledCount}
                              </div>
                              <div className="text-xs flex items-center text-gray-500">
                                <div className="w-3 h-3 flex items-center justify-center content-center bg-red-200/80 rounded-full mr-1">
                                  <div className="w-2 h-2 bg-red-500 rounded-full" />
                                </div>
                                Disabled: {disabledCount}
                              </div>
                            </div>
                          </div>
                        </span>
                      </span>
                      <Transition
                        show={checked}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 scale-50"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <CheckCircleIcon
                          className={classNames(
                            !checked ? "invisible" : "",
                            "h-5 w-5 text-primaryColor"
                          )}
                          aria-hidden="true"
                        />
                      </Transition>
                      <span
                        className={classNames(
                          active ? "border" : "border-2",
                          checked
                            ? "border-primaryColor"
                            : "border-transparent",
                          "pointer-events-none absolute -inset-px rounded-lg"
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
                {categories.map((category) => (
                  <RadioGroup.Option
                    key={category.name}
                    value={category}
                    className={({ checked, active }) =>
                      classNames(
                        checked ? "border-transparent" : "border-gray-300",
                        active
                          ? "border-primaryColor ring-2 ring-primaryColor"
                          : "",
                        "relative flex cursor-pointer rounded-lg border transition-all duration-200 bg-white/60 backdrop-blur p-4 shadow-sm focus:outline-none"
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <div className="flex-grow flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {category.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-xs text-gray-500"
                              >
                                {category.description}
                              </RadioGroup.Description>
                            </div>
                            <div className="flex-shrink-0 mt-2">
                              <RadioGroup.Description
                                as="span"
                                className="text-xs font-medium text-gray-800"
                              >
                                {category.count} word
                                {category.count !== 1 && "s"} total
                              </RadioGroup.Description>
                              <div className="flex items-center space-x-4 mt-2">
                                <div className="text-xs flex items-center text-gray-500">
                                  <div className="w-3 h-3 flex items-center justify-center content-center bg-green-200/80 rounded-full mr-1">
                                    <div className="w-2 h-2 bg-green-500 rounded-full" />
                                  </div>
                                  Enabled: {category.enabledCount}
                                </div>
                                <div className="text-xs flex items-center text-gray-500">
                                  <div className="w-3 h-3 flex items-center justify-center content-center bg-red-200/80 rounded-full mr-1">
                                    <div className="w-2 h-2 bg-red-500 rounded-full" />
                                  </div>
                                  Disabled: {category.disabledCount}
                                </div>
                              </div>
                            </div>
                          </span>
                        </span>
                        <Transition
                          show={checked}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 scale-50"
                          enterTo="opacity-100 scale-100"
                          leave="transition ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? "invisible" : "",
                              "h-5 w-5 text-primaryColor"
                            )}
                            aria-hidden="true"
                          />
                        </Transition>
                        <span
                          className={classNames(
                            active ? "border" : "border-2",
                            checked
                              ? "border-primaryColor"
                              : "border-transparent",
                            "pointer-events-none absolute -inset-px rounded-lg"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          <div className="my-6 flex justify-between w-full">
            <label className="block text-sm font-medium mt-6 text-gray-500">
              Word list
            </label>
            <div className="self-end">
              <input
                id="searchTerm"
                name="searchTerm"
                type="text"
                placeholder="Search word.."
                className="w-full text-base md:text-sm bg-white border border-gray-300 rounded-lg shadow form-input focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-primaryColor/50 focus:border-primaryColor/50 text-gray"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <WordsTable
            columns={columns}
            data={filteredData}
            setShowAddEditModal={setShowAddEditModal}
            setActiveWord={setActiveWord}
            getDictionary={getDictionary}
          />
        </div>
      </div>
    </>
  );
};

export default Dictionary;
