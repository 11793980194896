import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import { useState } from "react";
import { SmalLoadingIndicator } from "../Helpers/LoadingComponents";
import { ChartPieIcon } from "@heroicons/react/24/outline";

const COLORS = [
  "#fff100",
  "#ff8c00",
  "#e81123",
  "#ec008c",
  "#00188f",
  "#00bcf2",
  "#00b294",
  "#009e49",
  "#bad80a",
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 0) * cos;
  const sy = cy + (outerRadius + 0) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 12;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  // Remove "Language" from the category name
  const category = payload.category.replace("Language", "");

  return (
    <g className="cursor-pointer">
      <text x={cx} y={cy} dy={8} textAnchor="middle" fontSize={15} fill={fill}>
        {category}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill="#2F4858"
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={"#2F4858"}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={4} fill={"#2F4858"} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        fontSize={14}
        textAnchor={textAnchor}
        fill="#333"
      >{`Count: ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        fontSize={14}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const renderInActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, name } =
    props;

  return (
    <g className="cursor-pointer">
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={COLORS[name % COLORS.length]}
      />
    </g>
  );
};

const CategoriesChart = ({ categoriesData, isLoading, creationDate }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <div className="w-full min-h-full bg-white rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
      <div className="relative px-6 py-6 justify-between flex items-center">
        <div className="flex items-center space-x-3">
          <ChartPieIcon className="w-10 h-10" />
          <div className="flex flex-col">
            <h3 className="text-lg font-medium text-gray-900">
              Non-inclusive language
            </h3>
            <p className="text-sm text-gray-500">
              Type of non-inclusive language used in your workspace
            </p>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200"></div>
      {isLoading && (
        <div className="flex items-center justify-center h-[300px]">
          <SmalLoadingIndicator />
        </div>
      )}
      {categoriesData.length === 0 && !isLoading && (
        <div className="text-gray-600 flex flex-col items-center content-center justify-center text-center h-[300px] px-8 text-md">
          <div>
            Not enough data. But hey,{" "}
            <span className="text-green-600">that's a good thing!</span> <br />{" "}
            Please try another date range.
          </div>
        </div>
      )}
      {categoriesData.length > 0 && !isLoading && (
        <ResponsiveContainer
          width="100%"
          height={300}
          className="cursor-pointer"
        >
          <PieChart width="100%" height={300}>
            <Pie
              activeIndex={activeIndex}
              isAnimationActive={false}
              activeShape={renderActiveShape}
              inactiveShape={renderInActiveShape}
              data={categoriesData}
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={80}
              fill="#00000"
              dataKey="amount"
              onMouseEnter={onPieEnter}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default CategoriesChart;
