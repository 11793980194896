import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { state, useSnapshot } from "../../state/stateStore";
import { truncate } from "../Helpers/Helpers";
import {
  CheckCircleIcon,
  ClockIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ExclamationTriangleIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/outline";
import { Disclosure, Transition } from "@headlessui/react";
import moment from "moment";
import DateRangeSelector from "./DateRangeSelector";
import RadarCategoriesChart from "./RadarCategoriesChart";
import OccurancesChart from "./OccurancesChart";
import * as api from "../../services/api";

const PersonIncidentsTable = ({ incidents }) => {
  const navigate = useNavigate();
  return (
    <div className="overflow-x-auto">
      <div className="py-2 align-middle inline-block min-w-full">
        <div className="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 text-gray-500">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 "
                >
                  Incident
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 "
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500  "
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 "
                >
                  Submission date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 "
                >
                  Handler
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 "
                ></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {incidents.map((incident) => (
                <tr key={incident._id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {incident.subject}
                    </div>
                    <div className="text-sm text-gray-500">
                      {truncate(incident.note, 50)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {incident.status === "open" && (
                      <span className="px-2 mt-1 inline-flex text-xs py-1 rounded-full items-center bg-yellow-100 text-yellow-800">
                        Open
                      </span>
                    )}
                    {incident.status === "resolved" && (
                      <span className="px-2 mt-1 inline-flex text-xs py-1 rounded-full items-center bg-green-100 text-green-700">
                        <CheckCircleIcon
                          className="-ml-1 mr-1 h-4 w-4"
                          aria-hidden="true"
                        />
                        Resolved
                      </span>
                    )}
                    {incident.status === "in_progress" && (
                      <span className="px-2 mt-1 inline-flex text-xs py-1  rounded-full items-center bg-blue-100 text-blue-700">
                        <ClockIcon
                          className="-ml-1 mr-1 h-4 w-4"
                          aria-hidden="true"
                        />
                        In progress
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{incident.type}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {moment(incident.submission_date).format("MMM Do YYYY")}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {incident.handler && (
                      <div className="flex items-center">
                        <img
                          src={incident.handler.image}
                          alt=""
                          className="w-6 h-6 rounded-full"
                        />
                        <span className="ml-2">{incident.handler.name}</span>
                      </div>
                    )}
                    {!incident.handler && (
                      <span className="px-2 inline-flex text-xs leading-5 rounded-full bg-yellow-100 text-yellow-800">
                        Unassigned
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div
                      onClick={() => {
                        navigate("/incidents/" + incident._id);
                      }}
                      className="relative w-8 text-sm text-primaryColor cursor-pointer hover:text-primaryColor transition-all after:absolute after:bg-primaryColor after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300"
                    >
                      View
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ProfilePage = () => {
  let navigate = useNavigate();
  const params = useParams();
  const userId = params.userId;

  // Workspace from global state
  const snap = useSnapshot(state);
  const workspace = snap.workspace;

  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState("all");
  // State for date range selector
  const [startDate, setStartDate] = useState(
    moment(workspace.create_date).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());

  const updateDateRange = (dateRange) => {
    setDateRange(dateRange);
  };

  const getYearlyOccurances = async (year) => {
    let data = await api.getYearlyOccurances(year);
    setUserData({ ...userData, yearly_occurances: data.yearly_occurances });
  };

  const fetchUser = async () => {
    setIsLoading(true);
    let userData = await api.getUserById(userId, startDate, endDate);
    const data = await api.getUserYearlyOccurances(userId, moment().year());
    userData.yearly_occurances = data.yearly_occurances;
    setUserData(userData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, [startDate, endDate]);

  useEffect(() => {
    fetchUser();
  }, [userId]);

  if (userData) {
    //Calculate amount of words in userData.occurances

    let wordCount = userData.occurances.reduce((acc, cur) => {
      return acc + cur.words.length;
    }, 0);

    // Calculate top words from userData.occurances
    // Occurance model is { words: [Array], date: DateTime.. } and one user can have multiple occurances of the same word
    let topWords = userData.occurances.reduce((acc, cur) => {
      cur.words.forEach((word) => {
        if (acc[word.word]) {
          acc[word.word].count += 1;
          acc[word.word].categories = [
            ...acc[word.word].categories,
            ...word.categories,
          ];
        } else {
          acc[word.word] = {
            count: 1,
            categories: word.categories,
          };
        }
      });
      return acc;
    }, {});

    // Loop the top words object and sort it by value (amount) and return the top 5 words as an array of objects with word, word categories and occurance count
    topWords = Object.keys(topWords)
      .sort((a, b) => topWords[b] - topWords[a])
      .slice(0, 5)
      .map((word) => {
        // Remove duplicates from categories
        topWords[word].categories = [...new Set(topWords[word].categories)];
        return {
          word: word,
          count: topWords[word].count,
          categories: topWords[word].categories,
        };
      });

    // Calculate top words from occurances and include word count and word categories
    // Each occurance is a single word and has categories

    // Calculate words by category in occurances (for chart)
    const categories = userData.occurances.reduce((acc, cur) => {
      cur.words.forEach((word) => {
        word.categories.forEach((category) => {
          if (acc[category]) {
            acc[category] += 1;
          } else {
            acc[category] = 1;
          }
        });
      });
      return acc;
    }, {});

    const categoriesData = Object.keys(categories).map((category) => {
      return { category: category, amount: categories[category] };
    });

    // Calculate average amount of occurances per day during the selected date range (startDate - endDate)
    let days = moment(endDate).diff(moment(startDate), "days");
    if (days === 0) {
      days = 1;
    }
    const averageWordsPerDay = wordCount / days;

    return (
      <div className="pt-6 pb-10 animate-fade z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <button
            onClick={() => navigate(-1)}
            className="relative flex text-sm mt-4 items-center cursor-pointer hover:text-primaryColor transition-all after:absolute after:bg-primaryColor after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300"
          >
            <ChevronLeftIcon
              className="float-left h-4 w-4 mr-1 -ml-1"
              aria-hidden="true"
            />
            Back
          </button>
          <div className="flex items-center space-x-5 mt-8">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-full"
                  src={userData.user.user_image}
                  alt={userData.user.user_name}
                />
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-3xl font-bold text-gray-900">
                {userData.user.user_name}
              </h1>
              <p className="text-md font-medium text-gray-500">
                {userData.user.user_title}
              </p>
            </div>
          </div>
          <div className="flex z-20 mt-6 sticky top-2 mb-2 rounded-lg border border-gray-200 bg-white/60 backdrop-blur p-4 shadow-sm">
            <DateRangeSelector
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              updateDateRange={updateDateRange}
              workspace={workspace}
            />
          </div>
          <div className="grid grid-cols-1 gap-6 mt-10 sm:grid-cols-4 lg:grid-cols-4">
            <div className="px-10 h-[12rem] overflow-hidden bg-[#fff1f0] bg-gradient-to-br from-rose-50/75 to-rose-100/75 rounded-lg items-center flex flex-col text-center justify-center animate-enter shadow-sm border border-primaryColor/50 transition-all">
              <div className="text-6xl text-primaryColor font-semibold">
                {wordCount}
              </div>
              <h3 className="text-md mt-2 font-medium text-gray-800">
                Non-inclusive words total
              </h3>
            </div>
            <div className="px-10 overflow-hidden bg-sky-50 bg-gradient-to-br from-sky-50/70 to-sky-100/70 rounded-lg flex flex-col items-center text-center justify-center animate-enter shadow-sm border border-sky-200 transition-all">
              <div className="text-6xl text-sky-600 font-semibold">
                {averageWordsPerDay > 1.0
                  ? averageWordsPerDay.toFixed(0)
                  : averageWordsPerDay.toFixed(2)}
              </div>
              <h3 className="text-md mt-2 font-medium text-gray-800">
                Avg. words per day
              </h3>
            </div>
            <div className="px-10 overflow-hidden bg-emerald-50  bg-gradient-to-br from-emerald-50/70 to-emerald-100/50 rounded-lg flex flex-col items-center text-center justify-center border border-emerald-200 animate-enter shadow-sm transition-all">
              <div className="text-6xl text-emerald-600 font-semibold">
                {userData.reported_incidents.length}
              </div>
              <h3 className="text-md mt-2 font-medium text-gray-800">
                DEI incidents reported
              </h3>
            </div>
            <div className="px-10 overflow-hidden bg-emerald-50  bg-gradient-to-br from-yellow-50/70 to-yellow-100/50 rounded-lg flex flex-col items-center text-center justify-center border border-yellow-200 animate-enter shadow-sm transition-all">
              <div className="text-6xl text-yellow-600 font-semibold">
                {userData.related_incidents.length}
              </div>
              <h3 className="text-md mt-2 font-medium text-yellow-800">
                DEI incidents involved
              </h3>
            </div>
            <div className="col-span-2">
              <OccurancesChart
                yearlyData={userData.yearly_occurances}
                getYearlyOccurances={getYearlyOccurances}
                isLoading={isLoading}
                creationDate={workspace.create_date}
              />
            </div>
            <div className="col-span-2">
              <RadarCategoriesChart
                categoriesData={categoriesData}
                isLoading={isLoading}
              />
            </div>
            {(userData.reported_incidents.length > 0 ||
              userData.related_incidents.length > 0) && (
              <div className="col-span-4 h-full overflow-hidden bg-white rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
                <div className="relative flex flex-col mb-2">
                  <div className="relative px-6 py-6 justify-between flex items-center">
                    <div className="flex items-center space-x-3">
                      <ExclamationTriangleIcon className="w-8 h-8" />
                      <div className="flex flex-col">
                        <h3 className="text-lg font-medium text-gray-900">
                          DEI incidents
                        </h3>
                        <p className="text-sm text-gray-500">
                          This user has{" "}
                          {userData.reported_incidents.length +
                            userData.related_incidents.length}{" "}
                          total incidents
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 px-6 py-4">
                    {userData.reported_incidents.length > 0 && (
                      <div className="border-gray-200 bg-gray-50 border rounded-lg p-3 px-4">
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex cursor-pointer mt-2 w-full mb-1  justify-between rounded-lg items-center">
                                <label className="block text-sm cursor-pointer font-medium text-gray-900">
                                  Reported incidents
                                  <span className="inline-flex ml-2 items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/40">
                                    {userData.reported_incidents.length}
                                  </span>
                                </label>
                                <ChevronUpIcon
                                  className={`${
                                    open ? "rotate-180 transform " : ""
                                  } h-5 w-5 text-gray-500 ml-2 transition-all`}
                                />
                              </Disclosure.Button>
                              <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                              >
                                <Disclosure.Panel>
                                  <PersonIncidentsTable
                                    incidents={userData.reported_incidents}
                                    isLoading={isLoading}
                                  />
                                </Disclosure.Panel>
                              </Transition>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    )}
                    {userData.related_incidents.length > 0 && (
                      <div className="border-gray-200 bg-gray-50 border rounded-lg mt-4 p-3 px-4">
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex cursor-pointer mt-2 w-full mb-1 justify-between rounded-lg items-center">
                                <label className="block text-sm cursor-pointer font-medium text-gray-900">
                                  Involved incidents{" "}
                                  <span className="inline-flex ml-2 items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/40">
                                    {userData.related_incidents.length}
                                  </span>
                                </label>
                                <ChevronUpIcon
                                  className={`${
                                    open ? "rotate-180 transform " : ""
                                  } h-5 w-5 text-gray-500 ml-2 transition-all`}
                                />
                              </Disclosure.Button>
                              <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                              >
                                <Disclosure.Panel>
                                  <PersonIncidentsTable
                                    incidents={userData.related_incidents}
                                    isLoading={isLoading}
                                  />
                                </Disclosure.Panel>
                              </Transition>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {userData.user.muted_words.length > 0 && (
              <div className="overflow-hidden bg-white rounded-lg col-span-2 animate-enter shadow-sm transition-all border border-gray-200">
                <div className="relative px-6 py-6 justify-between flex items-center">
                  <div className="flex items-center space-x-3">
                    <SpeakerXMarkIcon className="w-8 h-8" />
                    <div className="flex flex-col">
                      <h3 className="text-md font-medium text-gray-900">
                        Muted words
                      </h3>
                      <p className="text-md text-gray-500">
                        Words that the user has muted
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border-t border-gray-200">
                  <div className="mt-2 px-6 flex flex-col divide-y divide-gray-200">
                    {userData.user.muted_words.map((word, i) => (
                      <div className="flex w-full items-center justify-between py-4">
                        <div>
                          <div className="text-md font-medium">{word.word}</div>
                        </div>
                        <div className="text-gray-500 text-sm">
                          Until {moment(word.until).format("MMMM Do YYYY")}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="overflow-hidden bg-white rounded-lg  col-span-2 animate-enter shadow-sm transition-all border border-gray-200">
              <div className="relative px-6 py-6 justify-between flex items-center">
                <div className="flex items-center space-x-3">
                  <ChatBubbleOvalLeftEllipsisIcon className="w-8 h-8" />
                  <div className="flex flex-col">
                    <h3 className="text-md font-medium text-gray-900">
                      Common words
                    </h3>
                    <p className="text-sm text-gray-500">
                      Words that {userData.user.user_name} uses the most
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200">
                {topWords.length > 0 && !isLoading && (
                  <div className="mt-2 px-6 flex flex-col divide-y divide-gray-200">
                    {topWords.map((word, i) => (
                      <div className="flex w-full py-4 items-center justify-between">
                        <div className="w-3/4">
                          <div className="text-md font-medium">
                            {i + 1}. {word.word}
                          </div>
                          <div className="text-gray-500 text-sm">
                            {word.categories.map((category) => {
                              return <span className="mr-1">{category}</span>;
                            })}
                          </div>
                        </div>
                        <div className="flex text-md font-medium">
                          {word.count} times
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default ProfilePage;
