/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { state, useSnapshot } from "../state/stateStore";
import { NavLink, useLocation } from "react-router-dom";
import {
  HomeIcon,
  LifebuoyIcon,
  XCircleIcon,
  CogIcon,
  CreditCardIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  ChartBarSquareIcon,
  BookOpenIcon,
  AcademicCapIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavItems = (props) => {
  const pathname = props.pathname;
  const workspace = props.workspace;
  return (
    <>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "bg-white text-primaryColor group flex items-center px-2 py-2 text-sm font-medium rounded-lg shadow"
            : "text-gray-800 hover:bg-white hover:text-gray-800 hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
        }
        to="/dashboard"
      >
        <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
          <HomeIcon
            className={classNames(
              pathname === "/dashboard" ? "text-primaryColor" : "text-gray-400",
              "flex-shrink-0 h-5 w-5 hover:text-gray-800"
            )}
            aria-hidden="true"
          />
        </div>
        Dashboard
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "bg-white text-primaryColor group flex items-center px-2 py-2 text-sm font-medium rounded-lg shadow"
            : "text-gray-800 hover:bg-white hover:text-gray-800 hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
        }
        to="/incidents"
      >
        <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
          <ExclamationTriangleIcon
            className={classNames(
              pathname.includes("/incidents")
                ? "text-primaryColor"
                : "text-gray-400",
              "flex-shrink-0 h-5 w-5 hover:text-gray-800"
            )}
            aria-hidden="true"
          />
        </div>
        Incidents
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "bg-white text-primaryColor group flex items-center px-2 py-2 text-sm font-medium rounded-lg shadow"
            : "text-gray-800 hover:bg-white hover:text-gray-800 hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
        }
        to="/reporting"
      >
        <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
          <ChartBarSquareIcon
            className={classNames(
              pathname.includes("/reporting")
                ? "text-primaryColor"
                : "text-gray-400",
              "flex-shrink-0 h-5 w-5 hover:text-gray-800"
            )}
            aria-hidden="true"
          />
        </div>
        Reporting
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "bg-white text-primaryColor group flex items-center px-2 py-2 text-sm font-medium rounded-lg shadow"
            : "text-gray-800  hover:bg-white hover:text-gray-800 hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
        }
        to="/dictionary"
      >
        <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
          <BookOpenIcon
            className={classNames(
              pathname === "/dictionary"
                ? "text-primaryColor"
                : "text-gray-400",
              "flex-shrink-0 h-5 w-5 hover:text-gray-800"
            )}
            aria-hidden="true"
          />
        </div>
        Dictionary
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "bg-white text-primaryColor  group flex items-center px-2 py-2 text-sm font-medium rounded-lg shadow"
            : "text-gray-800  hover:bg-white hover:text-gray-800 hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
        }
        to="/education"
      >
        <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
          <AcademicCapIcon
            className={classNames(
              pathname === "/education" ? "text-primaryColor" : "text-gray-400",
              "flex-shrink-0 h-5 w-5 hover:text-gray-800"
            )}
            aria-hidden="true"
          />
        </div>
        Education
      </NavLink>
      {/* <a
        className="text-gray-800  hover:bg-white hover:text-gray-800 hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
        href="https://evergreen-app.notion.site/Help-Center-defd1351f1a64b6fabe5040f1c2697a5"
        target="_blank"
        rel="noreferrer"
      >
        <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
          <LifebuoyIcon
            className="group-hover:text-gray-800 text-gray-400 transition-all duration-300 flex-shrink-0 h-5 w-5"
            aria-hidden="true"
          />
        </div>
        Support
      </a> */}
    </>
  );
};

const WithNavigation = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  const handleLogOut = async () => {
    await axios.post("/auth/logout", {}, { withCredentials: true });
    window.location.href = "/";
  };
  const snap = useSnapshot(state);
  const user = snap.user;
  const workspace = snap.workspace;

  if (user && workspace) {
    const occurancePercentage = Math.round(
      (workspace.occurance_counter /
        process.env.REACT_APP_FREE_TIER_RESPONSES) *
        100
    );

    // Redirect to wizard if it's not completed
    if (workspace.wizard_completed === false && pathname !== "/wizard") {
      window.location.href = "/wizard";
    }

    let progressBarColor = "bg-green-500";
    if (occurancePercentage > 50 && occurancePercentage <= 75) {
      progressBarColor = "bg-yellow-500";
    } else if (occurancePercentage > 75) {
      progressBarColor = "bg-red-300";
    }
    return (
      <>
        <div className="h-full ">
          <Transition.Root show={sidebarOpen} as={Fragment} className="z-50">
            <Dialog
              as="div"
              className="fixed inset-0 flex z-50 md:hidden  border-r border-gray-200 "
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-50/100 backdrop-blur-lg">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-6 w-6 rounded-full focus:outline-none focus:ring-1 focus:ring-primaryColor focus:ring-inset"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XCircleIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div className="flex flex-shrink-0">
                      <div className="flex flex-shrink-0 px-3">
                        <img
                          className="h-12 w-auto rounded-xl shadow-lg border border-primaryColor/50"
                          src={process.env.PUBLIC_URL + "/favicon.png"}
                          alt="Dara"
                        />
                      </div>
                    </div>
                    <nav className="mt-5 px-2 space-y-1">
                      <NavItems pathname={pathname} user={user} />
                    </nav>
                  </div>
                  <div className="flex-shrink-0 flex-col px-2 py-2">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "bg-white text-primaryColor shadow group flex items-center px-2 py-2 text-sm font-medium rounded-lg"
                          : "text-gray-800 hover:bg-white hover:text-gray-800 hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
                      }
                      to="/settings"
                    >
                      <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
                        <CogIcon
                          className={classNames(
                            pathname.includes("/settings")
                              ? "text-primaryColor"
                              : "text-gray-400",
                            "flex-shrink-0 h-5 w-5"
                          )}
                          aria-hidden="true"
                        />
                      </div>
                      Settings
                    </NavLink>
                    {user.roles.includes("admin") && (
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "bg-white text-primaryColor shadow group flex items-center px-2 py-2 text-sm font-medium rounded-lg"
                            : "text-gray-800 hover:bg-white hover:text-gray-800 hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
                        }
                        to="/account"
                      >
                        <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
                          <CreditCardIcon
                            className={classNames(
                              pathname.includes("/account")
                                ? "text-primaryColor"
                                : "text-gray-400",
                              "flex-shrink-0 h-5 w-5"
                            )}
                            aria-hidden="true"
                          />
                        </div>
                        Account
                      </NavLink>
                    )}
                    <div
                      className=" hover:bg-white hover:text-gray-800 cursor-pointer hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
                      onClick={() => handleLogOut()}
                    >
                      <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
                        <ArrowLeftOnRectangleIcon
                          className={classNames(
                            pathname.includes("/logout")
                              ? "text-primaryColor"
                              : "text-gray-400",
                            "flex-shrink-0 h-5 w-5"
                          )}
                          aria-hidden="true"
                        />
                      </div>
                      Log out
                    </div>
                  </div>

                  <div className="flex-shrink-0 mt-2 flex p-4 pt-4 pb-4 border-t border-gray-300">
                    <a href="#" className="flex-shrink-0 w-full group block">
                      <div className="flex items-center">
                        <div>
                          <img
                            className="inline-block h-9 w-9 rounded-full"
                            src={user.user_image}
                            alt={user.user_name}
                          />
                        </div>
                        <div className="ml-3 flex">
                          <div>
                            <p className="text-sm font-medium text-gray-800">
                              {user.user_name}
                            </p>
                            <p className="text-xs text-black">
                              {workspace.workspace.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0  bg-gray-50/70 border-r border-gray-200">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex flex-shrink-0 px-3">
                  <div className="flex items-center space-x-2">
                    <img
                      className="h-8 w-auto rounded-lg shadow-lg border border-primaryColor/50"
                      src={process.env.PUBLIC_URL + "/favicon.png"}
                      alt="Dara"
                    />
                    <div className="text-xl font-rubik font-semibold text-black">
                      Dara
                    </div>
                  </div>
                </div>
                <nav className="mt-6 flex-1 px-2 space-y-1">
                  <NavItems
                    pathname={pathname}
                    user={user}
                    workspace={workspace}
                  />
                </nav>
              </div>
              {workspace.subscription.plan === "freemium" && (
                <div className="px-4 mb-2">
                  <div className="flex justify-between mb-1">
                    <span className="text-sm font-medium text-gray-800">
                      Free responses used
                    </span>
                    <span className="text-sm font-medium text-gray-800">
                      {workspace.occurance_counter} /{" "}
                      {process.env.REACT_APP_FREE_TIER_RESPONSES}
                    </span>
                  </div>
                  <div className="w-full bg-gray-300 rounded-full h-2">
                    <div
                      className={
                        progressBarColor +
                        " background-animate h-2 rounded-full"
                      }
                      style={{ width: occurancePercentage + "%" }}
                    ></div>
                  </div>
                </div>
              )}
              <div className="flex-shrink-0 flex-col px-2 py-2">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "bg-white text-primaryColor shadow group flex items-center px-2 py-2 text-sm font-medium rounded-lg"
                      : "text-gray-800 hover:bg-white hover:text-gray-800 hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
                  }
                  to="/settings"
                >
                  <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
                    <CogIcon
                      className={classNames(
                        pathname.includes("/settings")
                          ? "text-primaryColor"
                          : "text-gray-400",
                        "flex-shrink-0 h-5 w-5"
                      )}
                      aria-hidden="true"
                    />
                  </div>
                  Settings
                </NavLink>
                {user.roles.includes("admin") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "bg-white text-primaryColor shadow group flex items-center px-2 py-2 text-sm font-medium rounded-lg"
                        : "text-gray-800 hover:bg-white hover:text-gray-800 hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
                    }
                    to="/account"
                  >
                    <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
                      <CreditCardIcon
                        className={classNames(
                          pathname.includes("/account")
                            ? "text-primaryColor"
                            : "text-gray-400",
                          "flex-shrink-0 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                    </div>
                    Account
                  </NavLink>
                )}
                <div
                  className=" hover:bg-white hover:text-gray-800 cursor-pointer hover:bg-opacity-100 group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-300"
                  onClick={() => handleLogOut()}
                >
                  <div className="rounded-md mr-3 bg-white p-1 shadow ring-1 ring-slate-900/5">
                    <ArrowLeftOnRectangleIcon
                      className={classNames(
                        pathname.includes("/logout")
                          ? "text-primaryColor"
                          : "text-gray-400",
                        "flex-shrink-0 h-5 w-5"
                      )}
                      aria-hidden="true"
                    />
                  </div>
                  Log out
                </div>
              </div>

              <div className="flex-shrink-0 mt-2 flex p-4 pt-4 pb-4 border-t border-gray-300">
                <a href="#" className="flex-shrink-0 w-full group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-9 w-9 rounded-full"
                        src={user.user_image}
                        alt={user.user_name}
                      />
                    </div>
                    <div className="ml-3 flex">
                      <div>
                        <p className="text-sm font-medium text-gray-800">
                          {user.user_name}
                        </p>
                        <p className="text-xs text-black">
                          {workspace.workspace.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="md:pl-64 flex flex-col flex-1">
            <div className="absolute -top-[45rem] -z-10 opacity-50 blur-lg -z-10 overflow-hidden">
              <img
                src={process.env.PUBLIC_URL + "/beam.png"}
                className="w-[60rem] object-cover object-center"
              />
            </div>
            <div className="sticky top-0 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-primaryColor/30">
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-lg text-gray-700 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primaryGreen"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <main className="flex-1 z-50">{children}</main>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default WithNavigation;
