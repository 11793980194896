import { useEffect, useState } from "react";
import { state, useSnapshot } from "../../state/stateStore";
import * as api from "../../services/api";
import { truncate } from "../Helpers/Helpers";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  CheckCircleIcon,
  ClockIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { SubscribeIncidentBlock } from "../Helpers/Helpers";
import { SmalLoadingIndicator } from "../Helpers/LoadingComponents";
import { incidentCategories } from "../Helpers/Helpers";

const IncidentTable = ({ incidents }) => {
  const navigate = useNavigate();
  return (
    <div className="overflow-x-auto">
      <div className="py-2 align-middle inline-block min-w-full">
        <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 text-gray-500">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500  tracking-wider"
                >
                  Incident
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500  tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500  tracking-wider"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500  tracking-wider"
                >
                  Submission date
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500  tracking-wider"
                >
                  Reporter
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500  tracking-wider"
                >
                  Handler
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500  tracking-wider"
                ></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {incidents.map((incident) => (
                <tr key={incident._id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {incident.subject}
                    </div>
                    <div className="text-sm text-gray-500">
                      {truncate(incident.note, 30)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {incident.status === "open" && (
                      <span className="px-2 mt-1 inline-flex text-xs py-1 rounded-full items-center bg-yellow-100 text-yellow-800">
                        Open
                      </span>
                    )}
                    {incident.status === "resolved" && (
                      <span className="px-2 mt-1 inline-flex text-xs py-1 rounded-full items-center bg-green-100 text-green-700">
                        <CheckCircleIcon
                          className="-ml-1 mr-1 h-4 w-4"
                          aria-hidden="true"
                        />
                        Resolved
                      </span>
                    )}
                    {incident.status === "in_progress" && (
                      <span className="px-2 mt-1 inline-flex text-xs py-1  rounded-full items-center bg-blue-100 text-blue-700">
                        <ClockIcon
                          className="-ml-1 mr-1 h-4 w-4"
                          aria-hidden="true"
                        />
                        In progress
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {incident.type ? (
                        truncate(
                          incidentCategories.find(
                            (category) =>
                              category.id === parseInt(incident.type)
                          ).name,
                          20
                        )
                      ) : (
                        <span className="px-2 inline-flex text-xs leading-5 rounded-full bg-gray-100 text-gray-800">
                          Not set
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {moment(incident.submission_date).format("MMM Do YYYY")}
                    </div>
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {!incident.anonymous && (
                      <div className="flex items-center">
                        <img
                          src={incident.reporter.image}
                          alt=""
                          className="w-6 h-6 rounded-full"
                        />
                        <span className="ml-2">{incident.reporter.name}</span>
                      </div>
                    )}
                    {incident.anonymous && (
                      <span className="px-2 inline-flex text-xs leading-5 rounded-full bg-gray-100 text-gray-800">
                        Anonymous
                      </span>
                    )}
                  </td> */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {incident.handler && (
                      <div className="flex items-center">
                        <img
                          src={incident.handler.image}
                          alt=""
                          className="w-6 h-6 rounded-full"
                        />
                        <span className="ml-2">{incident.handler.name}</span>
                      </div>
                    )}
                    {!incident.handler && (
                      <span className="px-2 inline-flex text-xs leading-5 rounded-full bg-yellow-100 text-yellow-800">
                        Unassigned
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div
                      onClick={() => {
                        navigate("/incidents/" + incident._id);
                      }}
                      className="relative w-8 text-sm text-primaryColor cursor-pointer hover:text-primaryColor transition-all after:absolute after:bg-primaryColor after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300"
                    >
                      View
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Incidents = () => {
  const [incidents, setIncidents] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchIncidents = async () => {
      setLoading(true);
      const data = await api.getIncidents();
      setIncidents(data.incidents);
      setLoading(false);
    };
    fetchIncidents();
  }, []);

  // Sort incident that are assigned to current user and not resolved
  const snap = useSnapshot(state);
  const user = snap.user;
  const workspace = snap.workspace;

  const assignedIncidents = incidents.filter(
    (incident) =>
      incident.status !== "resolved" &&
      incident.handler_user_id === user.user_id
  );

  // Sort open incidents that are not assigned to current user
  const unassignedIncidents = incidents.filter(
    (incident) =>
      incident.status === "open" && incident.handler_user_id !== user.user_id
  );

  // Sort resolved incidents
  const resolvedIncidents = incidents.filter(
    (incident) => incident.status === "resolved"
  );

  let noIncidents = false;

  if (
    assignedIncidents.length === 0 &&
    unassignedIncidents.length === 0 &&
    resolvedIncidents.length === 0
  ) {
    noIncidents = true;
  }

  return (
    <div className="max-w-7xl relative mx-auto px-4 sm:px-6 md:px-8">
      <div className="relative mt-8 mb-10">
        <div className="flex items-center">
          <div className="flex-auto">
            <h1 className="text-2xl text-primaryColor font-rubik font-semibold">
              Incidents
            </h1>
            <p className="text-gray-600 mt-1 text-md">
              Manage and track DEI-incidents reported by the people in your
              organization.
            </p>
          </div>
          {user.roles.includes("admin") && (
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <div
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-lg cursor-pointer bg-black px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryColor"
                onClick={() => navigate("/account/users/manage")}
              >
                <UserCircleIcon className="h-5 w-5" aria-hidden="true" />
                Manage incident handlers
              </div>
            </div>
          )}
        </div>
      </div>
      {!loading && (
        <>
          {workspace.subscription.plan === "freemium" && (
            <SubscribeIncidentBlock />
          )}
          <div
            className={workspace.subscription.plan === "freemium" ? "blur" : ""}
          >
            {!noIncidents && (
              <>
                {assignedIncidents.length > 0 && (
                  <div className="flex flex-col animate-fade">
                    <label className="block text-sm font-medium mb-1 text-gray-500">
                      Assigned to me
                    </label>
                    <IncidentTable incidents={assignedIncidents} />
                  </div>
                )}
                {unassignedIncidents.length > 0 && (
                  <div className="flex mt-6 flex-col animate-fade">
                    <label className="block text-sm font-medium mb-1 text-gray-500">
                      Unassigned incidents
                    </label>
                    <IncidentTable incidents={unassignedIncidents} />
                  </div>
                )}
                {resolvedIncidents.length > 0 && (
                  <div className="flex mt-6 flex-col animate-fade">
                    <label className="block text-sm font-medium mb-1 text-gray-500">
                      Resolved incidents
                    </label>
                    <IncidentTable incidents={resolvedIncidents} />
                  </div>
                )}
              </>
            )}
          </div>
          {noIncidents && (
            <div className="w-full flex items-center content-center justify-center animate-enter">
              <div className="max-w-3xl flex items-center content-center justify-center">
                <div className="text-center inline-block p-8 rounded-lg mt-48 border bg-white/30 shadow-sm backdrop-blur border-gray-300">
                  <div className="text-7xl">✨</div>
                  <h3 className="mt-2 text-xl font-semibold text-gray-900">
                    No DEI-incidents
                  </h3>
                  <p className="mt-1 text-md text-gray-500">
                    There are no DEI-incidents to show, which is a good thing!
                    When an incident is reported by a member of your workspace
                    it will show up here.
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {loading && (
        <div className="w-full flex items-center content-center justify-center animate-enter">
          <SmalLoadingIndicator />
        </div>
      )}
    </div>
  );
};

export default Incidents;
