import { Dialog, Transition, Switch, Listbox } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import {
  DocumentPlusIcon,
  CheckIcon,
  ChevronUpDownIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { useForm, Controller } from "react-hook-form";
import * as api from "../../services/api";
import { categories } from "../Helpers/Helpers";
import { WithContext as ReactTags } from "react-tag-input";

const AddEditModal = (props) => {
  const [selected, setSelected] = useState("");
  const [success, setSuccess] = useState(false);
  const [alternativeWords, setAlternativeWords] = useState([]);

  const handleDelete = (i) => {
    setAlternativeWords(alternativeWords.filter((word, index) => index !== i));
  };

  const handleAddition = (word) => {
    setAlternativeWords([...alternativeWords, word]);
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const closeModal = () => {
    props.closeModal();
    setSuccess(false);
    setSelected("");
    setAlternativeWords([]);
    reset();
  };

  //set default values if editing a word
  useEffect(() => {
    if (props.word) {
      console.log(props.word);
      reset({
        word: props.word.word,
        plural: props.word.plural,
        definition: props.word.definition,
        alternative_words: props.word.alternative_words,
        enabled: props.word.enabled,
        categories: props.word.categories,
      });
      setSelected(props.word.categories);
      let alternative_words = [];
      props.word.alternative_words.forEach((word) => {
        alternative_words.push({ id: word, text: word });
      });
      setAlternativeWords(alternative_words);
    } else {
      reset();
      setSelected("");
      setAlternativeWords([]);
      reset();
    }
  }, [props.word]);

  useEffect(() => {
    if (!props.word) {
      reset({});
      setSelected("");
      setAlternativeWords([]);
    }
  }, [props.open]);

  const onSubmit = async (data) => {
    data.alternative_words = alternativeWords;
    if (!props.word) {
      const res = await api.addWord(data);
      if (res.success) {
        setSuccess(true);
      }
    } else {
      data.wordId = props.word._id;
      const res = await api.editWord(data);
      if (res.success) {
        setSuccess(true);
      }
    }
  };
  let editable = true;
  if (props.word) {
    editable = props.word.editable;
  }

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-[2px]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="div"
                  className="text-lg font-medium items-center flex space-x-3 text-primaryColor"
                >
                  <div className="flex items-center justify-center h-8 w-8 rounded-lg bg-primaryColor bg-opacity-20">
                    <DocumentPlusIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  {props.word ? (
                    <div>Edit word</div>
                  ) : (
                    <div>Add a new word</div>
                  )}
                </Dialog.Title>
                {!success && (
                  <>
                    <p className="text-sm mt-3 text-gray-700">
                      {props.word
                        ? "You are editing word " + props.word.word + "."
                        : "Add a new word to the dictionary, Dara will learn it and respond to it according to the response you provide."}
                    </p>
                    <form id="word-form" onSubmit={handleSubmit(onSubmit)}>
                      <div className="mt-4 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
                        {editable && (
                          <>
                            <div className="sm:col-span-6">
                              <label
                                htmlFor="word"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Word
                              </label>
                              <div className="mt-2 flex w-full rounded-lg shadow-sm">
                                <input
                                  type="text"
                                  name="word"
                                  id="word"
                                  {...register("word", { required: true })}
                                  required
                                  placeholder="Enter the word to be detected"
                                  className="flex-1 focus:ring-primaryColor focus:border-primaryColor block w-full min-w-0 rounded-lg sm:text-sm border-gray-300"
                                />
                              </div>
                              {errors.word && (
                                <span className="text-sm mt-1 text-red-500">
                                  This field is required
                                </span>
                              )}
                            </div>
                            <div className="sm:col-span-6">
                              <label
                                htmlFor="word"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Plural
                              </label>
                              <div className="mt-2 flex w-full rounded-lg shadow-sm">
                                <input
                                  type="text"
                                  name="plural"
                                  id="plural"
                                  {...register("plural", { required: true })}
                                  required
                                  placeholder="Enter the plural of the word"
                                  className="flex-1 focus:ring-primaryColor focus:border-primaryColor block w-full min-w-0 rounded-lg sm:text-sm border-gray-300"
                                />
                              </div>
                              {errors.plural && (
                                <span className="text-sm mt-1 text-red-500">
                                  This field is required
                                </span>
                              )}
                            </div>
                            <div className="sm:col-span-6">
                              <label
                                htmlFor="categories"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Word category
                              </label>
                              <div className="mt-2 flex w-full rounded-lg shadow-sm">
                                <Controller
                                  control={control}
                                  name="categories"
                                  render={({ field: { onChange } }) => (
                                    <Listbox
                                      value={selected}
                                      onChange={(e) => {
                                        onChange(e);
                                        setSelected(e);
                                      }}
                                    >
                                      <div className="relative z-50 mt-1 w-full h-full">
                                        <Listbox.Button className="relative w-full cursor-default rounded-lg border  border-gray-300 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-primaryColor focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryColor sm:text-sm">
                                          {selected.length > 0 && (
                                            <span className="flex items-center">
                                              {selected}
                                            </span>
                                          )}
                                          {selected.length === 0 && (
                                            <span className="text-gray-500">
                                              Select category
                                            </span>
                                          )}
                                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon
                                              className="h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </Listbox.Button>
                                        <Transition
                                          as={Fragment}
                                          leave="transition ease-in duration-100"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <Listbox.Options className="absolute mt-1 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {categories.map(
                                              (category, categoryIdx) => (
                                                <Listbox.Option
                                                  key={categoryIdx}
                                                  className={({ active }) =>
                                                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                                      active
                                                        ? "bg-primaryColor/10 text-primaryColor"
                                                        : "text-gray-900"
                                                    }`
                                                  }
                                                  value={category.name}
                                                >
                                                  {({ selected }) => (
                                                    <>
                                                      <span
                                                        className={`block truncate ${
                                                          selected
                                                            ? "font-medium"
                                                            : "font-normal"
                                                        }`}
                                                      >
                                                        {category.name}
                                                      </span>
                                                      {selected ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primaryColor">
                                                          <CheckIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                          />
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </Listbox.Option>
                                              )
                                            )}
                                          </Listbox.Options>
                                        </Transition>
                                      </div>
                                    </Listbox>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-6">
                              <label
                                htmlFor="word"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Definition
                              </label>
                              <div className="mt-2 flex w-full rounded-lg shadow-sm">
                                <textarea
                                  type="text"
                                  name="definition"
                                  id="definition"
                                  {...register("definition", {
                                    required: true,
                                  })}
                                  required
                                  placeholder="Enter the definition of the word"
                                  className="flex-1 focus:ring-primaryColor focus:border-primaryColor block w-full min-w-0 rounded-lg sm:text-sm border-gray-300"
                                />
                              </div>
                              {errors.definition && (
                                <span className="text-sm mt-1 text-red-500">
                                  This field is required
                                </span>
                              )}
                            </div>
                            <div className="sm:col-span-6 my-2">
                              <label
                                htmlFor="word"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Alternative words
                              </label>
                              <p className="text-xs mt-1 text-gray-600">
                                Dara will suggest these alternative words to use
                                instead of the non-inclusive word.
                              </p>
                              <div className="mt-1 flex w-full">
                                <Controller
                                  name="alternative_words"
                                  control={control}
                                  render={({
                                    field: {
                                      onChange,
                                      onBlur,
                                      name,
                                      value,
                                      ref,
                                    },
                                  }) => (
                                    <ReactTags
                                      value={value}
                                      allowDragDrop={false}
                                      tags={alternativeWords}
                                      placeholder="Enter alternative word.."
                                      handleDelete={handleDelete}
                                      handleAddition={handleAddition}
                                      inputFieldPosition="inline"
                                    />
                                  )}
                                />
                              </div>
                              {errors.alternative_words && (
                                <span className="text-sm mt-1 text-red-500">
                                  This field is required
                                </span>
                              )}
                            </div>
                          </>
                        )}
                        <div className="sm:col-span-6 mt-1">
                          <label
                            htmlFor="word"
                            className="block text-sm font-medium text-gray-900"
                          >
                            Enable word
                          </label>
                          <div className="flex mt-2 items-center justify-between space-x-3">
                            <p className="text-sm w-3/4 text-gray-700">
                              Set the word to be enabled or disabled. If
                              disabled, Dara will not respond to the word.
                            </p>
                            <Controller
                              control={control}
                              defaultValue={true}
                              name="enabled"
                              render={({ field: { value, onChange } }) => (
                                <Switch
                                  checked={Boolean(value)}
                                  onChange={onChange}
                                  className={`${
                                    value ? "bg-green-500" : "bg-gray-500"
                                  } relative inline-flex h-6 w-11 items-center cursor-pointer rounded-full`}
                                >
                                  <span
                                    aria-hidden="true"
                                    className={`${
                                      value ? "translate-x-6" : "translate-x-1"
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                )}
                {success && (
                  <>
                    <div className="flex items-center mt-8 justify-center animate-enter">
                      <CheckCircleIcon
                        className="h-24 w-24 text-green-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-2 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-medium text-primaryColor"
                      >
                        {props.word ? "Word updated" : "Word added"}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {props.word
                            ? "The word has been updated successfully to dictionary."
                            : "The word has been added successfully to dictionary."}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div className="mt-6">
                  {props.word && !success && (
                    <button
                      type="submit"
                      className="focus:outline-none inline-flex float-right justify-center rounded-lg border border-transparent bg-primaryColor/10 px-4 py-2 text-sm font-medium text-primaryColor hover:bg-primaryColor/20 focus-visible:ring-offset-2"
                      form="word-form"
                    >
                      Save word
                    </button>
                  )}
                  {!props.word && !success && (
                    <button
                      type="submit"
                      className="focus:outline-none inline-flex float-right justify-center rounded-lg border border-transparent bg-primaryColor/10 px-4 py-2 text-sm font-medium text-primaryColor hover:bg-primaryColor/20 focus-visible:ring-offset-2"
                      form="word-form"
                    >
                      Add word
                    </button>
                  )}
                  <button
                    type="button"
                    className="focus:outline-none inline-flex  float-right justify-center rounded-lg border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-grey-900 hover:bg-grey-200 focus-visible:ring-2 focus-visible:ring-grey-500 focus-visible:ring-offset-2"
                    onClick={() => closeModal()}
                  >
                    {success ? "Close" : "Cancel"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddEditModal;
