import DateRangeSelector from "./DateRangeSelector";
import { state, useSnapshot } from "../../state/stateStore";
import { useState, useEffect } from "react";
import { SmalLoadingIndicator } from "../Helpers/LoadingComponents";
import * as api from "../../services/api";
import moment from "moment";
import {
  UserGroupIcon,
  HashtagIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from "@heroicons/react/24/outline";

import { SubscribeReportingBlock } from "../Helpers/Helpers";

// Components
import CategoriesChart from "./CategoriesChart";
import OccurancesChart from "./OccurancesChart";
import PeopleTable from "./PeopleTable";

const renderGrowthText = (growth) => {
  if (growth > 0) {
    return (
      <p className="mt-2 text-sm text-gray-600">
        <span className="text-green-700">{growth}%</span> more than the previous
        period.
      </p>
    );
  } else if (growth < 0) {
    return (
      <p className="mt-2 text-sm text-gray-600">
        <span className="text-red-700">{growth}%</span> less than the previous
        period.
      </p>
    );
  } else {
    return (
      <p className="mt-2 text-sm text-gray-600">
        <span className="text-gray-700">-</span> same as the previous period.
      </p>
    );
  }
};

const Reporting = () => {
  // Workspace from global state
  const snap = useSnapshot(state);
  const workspace = snap.workspace;

  // State for date range selector
  const [startDate, setStartDate] = useState(
    moment(workspace.create_date).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [reportingData, setReportingData] = useState(null);
  const [yearlyData, setYearlyData] = useState(null);
  const [memberData, setMemberData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // Fetch data from API and set state
      const data = await api.getReportingData(startDate, endDate);
      setReportingData(data.data);
      setIsLoading(false);
    };
    fetchData().catch(console.error);
  }, [startDate, endDate]);

  const updateDateRange = (dateRange) => {
    setDateRange(dateRange);
  };

  const getYearlyOccurances = async (year) => {
    const yearlyData = await api.getYearlyOccurances(year);
    setYearlyData(yearlyData.data);
  };

  const getMemberReportingData = async (limit, skip, search, sort) => {
    const memberData = await api.getMemberReportingData(
      startDate,
      endDate,
      limit,
      skip,
      search,
      sort
    );
    setMemberData(memberData);
  };

  if (reportingData) {
    // Calculate amount of unique users in  current occurances
    const uniqueUsers = reportingData.occurances.current.reduce((acc, cur) => {
      if (!acc.includes(cur.user_id)) {
        acc.push(cur.user_id);
      }
      return acc;
    }, []);

    // Calculate amount of unique users in  previous occurances
    const uniqueUsersPrevious = reportingData.occurances.previous.reduce(
      (acc, cur) => {
        if (!acc.includes(cur.user_id)) {
          acc.push(cur.user_id);
        }
        return acc;
      },
      []
    );

    let uniqueUsersGrowth = 0;
    if (uniqueUsersPrevious.length > 0) {
      uniqueUsersGrowth = Math.round(
        ((uniqueUsers.length - uniqueUsersPrevious.length) /
          uniqueUsersPrevious.length) *
          100
      );
    }

    // Calculate amount of words in current occurances (words are an array of words)
    const words = reportingData.occurances.current.reduce((acc, cur) => {
      acc = [...acc, ...cur.words];
      return acc;
    }, []);

    // Calculate words growth from previous period
    const wordsPrevious = reportingData.occurances.previous.reduce(
      (acc, cur) => {
        acc = [...acc, ...cur.words];
        return acc;
      },
      []
    );

    let wordsGrowth = 0;
    if (wordsPrevious.length > 0) {
      wordsGrowth = Math.round(
        ((words.length - wordsPrevious.length) / wordsPrevious.length) * 100
      );
    }

    let incidentsGrowth = -0;

    if (reportingData.incidents.previous.length > 0) {
      incidentsGrowth = Math.round(
        ((reportingData.incidents.current.length -
          reportingData.incidents.previous.length) /
          reportingData.incidents.previous.length) *
          100
      );
    }

    // Calculate top words from occurances and include word count and word categories
    // Each occurance has an array of word objects with a word and a category
    let topWords = reportingData.occurances.current.reduce((acc, cur) => {
      cur.words.forEach((word) => {
        if (acc[word.word]) {
          acc[word.word].count += 1;
          acc[word.word].categories = [
            ...acc[word.word].categories,
            ...word.categories,
          ];
        } else {
          acc[word.word] = {
            count: 1,
            categories: word.categories,
          };
        }
      });
      return acc;
    }, {});

    // Slice top words to 5
    const topWordsSize = Object.keys(topWords).length;

    // Remove duplicates from categories
    topWords = Object.keys(topWords).reduce((acc, cur) => {
      acc[cur] = {
        count: topWords[cur].count,
        categories: [...new Set(topWords[cur].categories)],
      };
      return acc;
    }, {});

    // Reduce top words to 5
    topWords = Object.keys(topWords).reduce((acc, cur, i) => {
      if (i < 5) {
        acc[cur] = topWords[cur];
      }
      return acc;
    }, {});

    // Sort top words by count
    topWords = Object.keys(topWords)
      .sort((a, b) => topWords[b].count - topWords[a].count)
      .reduce((acc, cur) => {
        acc[cur] = topWords[cur];
        return acc;
      }, {});

    // Create an array of categories an amount of occurances like this: const data = [{category: "Racism", amount: 10}, {category: "Sexism", amount: 5}, {category: "Homophobia", amount: 2}]
    // Each occurance has words as an array of objects with word and categories
    const categories = reportingData.occurances.current.reduce((acc, cur) => {
      cur.words.forEach((word) => {
        word.categories.forEach((category) => {
          if (acc[category]) {
            acc[category] += 1;
          } else {
            acc[category] = 1;
          }
        });
      });
      return acc;
    }, {});

    const categoriesData = Object.keys(categories).map((category) => {
      return { category: category, amount: categories[category] };
    });

    // Calculate top channels in occurances and get channel names from workspace.channels
    let topChannels = {};
    if (
      workspace.subscription.plan !== "freemium" &&
      workspace.subscription.plan !== "react"
    ) {
      topChannels = reportingData.occurances.current.reduce((acc, cur) => {
        if (acc[cur.channel_id]) {
          acc[cur.channel_id] += 1;
        } else {
          acc[cur.channel_id] = 1;
        }
        return acc;
      }, {});

      if (Object.keys(topChannels).length > 0) {
        //Replace channel_id with channel name and sort by amount
        topChannels = Object.keys(topChannels).map((channel) => {
          const channelName = workspace.channels.find(
            (c) => c.id === channel
          ).name;
          const channelId = workspace.channels.find((c) => c.id === channel).id;
          return {
            id: channelId,
            name: "#" + channelName,
            amount: topChannels[channel],
          };
        });
        topChannels.sort((a, b) => b.amount - a.amount);
      }

      // Get top category for each  topChannel from occurances

      if (topChannels && topChannels.length > 0) {
        topChannels = topChannels.map((channel) => {
          const topCategory = reportingData.occurances.current
            .filter((occurance) => occurance.channel_id === channel.id)
            .reduce((acc, cur) => {
              cur.words.forEach((word) => {
                word.categories.forEach((category) => {
                  if (acc[category]) {
                    acc[category] += 1;
                  } else {
                    acc[category] = 1;
                  }
                });
              });
              return acc;
            }, {});

          const topCategoryName = Object.keys(topCategory).reduce((a, b) =>
            topCategory[a] > topCategory[b] ? a : b
          );

          return {
            ...channel,
            topCategory: topCategoryName,
          };
        });
      }
    }

    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pb-10 h-full">
        <div className="relative mt-8">
          <div className="relative">
            <h1 className="text-2xl text-primaryColor font-semibold font-rubik mb-1">
              Reporting
            </h1>
            <p className="text-gray-600 text-md">
              Insights on non-inclusive language and DEI-incidents
            </p>
          </div>
        </div>
        <div className="relative">
          {(workspace.subscription.plan === "freemium" ||
            workspace.subscription.plan === "react") && (
            <SubscribeReportingBlock />
          )}
          <div
            className={
              workspace.subscription.plan === "freemium" ||
              workspace.subscription.plan === "react"
                ? "blur"
                : ""
            }
          >
            <div className="flex z-20 mt-6 sticky top-2 mb-2 rounded-lg border border-gray-200 bg-white/60 backdrop-blur p-4 shadow-sm">
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                updateDateRange={updateDateRange}
                workspace={workspace}
              />
            </div>
            {reportingData && (
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 mt-8 h-60">
                <div className="px-10 overflow-hidden bg-gradient-to-br from-rose-50/40 to-rose-100/40 bg-opacity-10 backdrop-blur-lg rounded-lg items-center flex text-center justify-center animate-enter shadow-sm border border-primaryColor/50 transition-all">
                  <div>
                    {isLoading ? (
                      <div className="flex justify-center">
                        <SmalLoadingIndicator />
                      </div>
                    ) : (
                      <div className="text-7xl font-semibold text-primaryColor">
                        {words.length}
                      </div>
                    )}

                    <h3 className="text-lg text-center font-medium text-gray-800">
                      Non-inclusive words used
                    </h3>
                    {renderGrowthText(wordsGrowth)}
                  </div>
                </div>
                <div className="px-10 overflow-hidden bg-gradient-to-br from-sky-50/50 to-sky-100/50  backdrop-blur-lg rounded-lg flex items-center text-center justify-center animate-enter shadow-sm border border-sky-200 transition-all">
                  <div>
                    {isLoading ? (
                      <div className="flex justify-center">
                        <SmalLoadingIndicator />
                      </div>
                    ) : (
                      <div className="flex justify-center items-center space-x-4 text-7xl font-semibold text-sky-600">
                        {uniqueUsers.length}
                      </div>
                    )}

                    <h3 className="text-lg text-center font-medium text-gray-800">
                      People using non-inclusive words
                    </h3>
                    {renderGrowthText(uniqueUsersGrowth)}
                  </div>
                </div>
                <div className="px-10 flex overflow-hidden bg-emerald-50  bg-gradient-to-br from-emerald-50/70 to-emerald-100/50 rounded-lg items-center text-center justify-center border border-emerald-200 animate-enter shadow-sm transition-all">
                  <div>
                    {isLoading ? (
                      <div className="flex justify-center">
                        <SmalLoadingIndicator />
                      </div>
                    ) : (
                      <div className="inline-block text-6xl font-semibold text-emerald-600">
                        {reportingData.incidents.current.length}
                      </div>
                    )}
                    <h3 className="text-lg text-center font-medium text-gray-800">
                      DEI incidents reported
                    </h3>
                    {renderGrowthText(incidentsGrowth)}
                  </div>
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 mt-8">
              <OccurancesChart
                yearlyData={yearlyData}
                getYearlyOccurances={getYearlyOccurances}
                isLoading={isLoading}
                creationDate={workspace.create_date}
              />
              <CategoriesChart
                categoriesData={categoriesData}
                isLoading={isLoading}
              />
            </div>
            {reportingData && (
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 mt-8 min-h-[25rem]">
                {/* <div className="overflow-hidden bg-white rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
                  <div className="relative px-6 py-6 justify-between flex items-center">
                    <div className="flex items-center space-x-3">
                      <UserGroupIcon className="w-8 h-8" />
                      <div className="flex flex-col">
                        <h3 className="text-md font-medium text-gray-900">
                          Frequent users
                        </h3>
                        <p className="text-sm text-gray-500">
                          Common users of non-inclusive language
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200">
                    {reportingData.top_users.length > 0 && !isLoading && (
                      <div className="mt-2 px-6 flex flex-col divide-y divide-gray-200">
                        {reportingData.top_users.map((user) => (
                          <div className="flex w-full items-center py-3 justify-between">
                            <div className="flex space-x-3 items-center">
                              <img
                                className="rounded-full w-10 h-10"
                                src={user.user_image}
                                alt="User"
                              />
                              <div className="flex flex-col">
                                <div className="text-sm font-medium">
                                  {user.user_name}
                                </div>
                                <div className="text-xs text-gray-500">
                                  {user.user_title}
                                </div>
                              </div>
                            </div>
                            <div className="flex text-md  font-medium">
                              {user.count}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {isLoading && <SmalLoadingIndicator />}
                  {topWordsSize === 0 && !isLoading && (
                    <div className="mt-4 flex flex-col space-y-4 px-6">
                      <div className="flex w-full items-center justify-between">
                        <div>
                          <div className="text-md  text-primaryColor font-medium">
                            No people
                          </div>
                          <div className="text-gray-600 text-sm">
                            No people during the selected time period
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div> */}
                <div className="overflow-hidden bg-white rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
                  <div className="relative px-6 py-6 justify-between flex items-center">
                    <div className="flex items-center space-x-3">
                      <ChatBubbleOvalLeftEllipsisIcon className="w-8 h-8" />
                      <div className="flex flex-col">
                        <h3 className="text-md font-medium text-gray-900">
                          Common words
                        </h3>
                        <p className="text-sm text-gray-500">
                          Words that are used the most
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200">
                    {topWordsSize > 0 && !isLoading && (
                      <div className="mt-2 px-6 flex flex-col divide-y divide-gray-200">
                        {Object.keys(topWords).map((word) => (
                          <div className="flex w-full py-4 items-center justify-between">
                            <div className="w-3/4">
                              <div className="text-sm font-medium">{word}</div>
                              <div className="text-gray-500 text-xs">
                                {topWords[word].categories.map((category) => {
                                  return (
                                    <span className="mr-1">{category}</span>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="flex text-md font-medium">
                              {topWords[word].count}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {isLoading && <SmalLoadingIndicator />}
                  {topWordsSize === 0 && !isLoading && (
                    <div className="mt-4 flex flex-col space-y-4 px-6">
                      <div className="flex w-full items-center justify-between">
                        <div>
                          <div className="text-md  text-primaryColor font-medium">
                            No words
                          </div>
                          <div className="text-gray-600 text-sm">
                            No non-inclusive words have been used during the
                            selected time period
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="overflow-hidden bg-white rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
                  <div className="relative px-6 py-6 justify-between flex items-center">
                    <div className="flex items-center space-x-3">
                      <HashtagIcon className="w-8 h-8" />
                      <div className="flex flex-col">
                        <h3 className="text-md font-medium text-gray-800">
                          Common channels
                        </h3>
                        <p className="text-sm text-gray-500">
                          Channels with most non-inclusive language
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200">
                    {Object.keys(topChannels).length > 0 && !isLoading && (
                      <div className="mt-2 px-6 flex flex-col divide-y divide-gray-200">
                        {topChannels.map((channel) => (
                          <div className="flex w-full py-4 items-center justify-between">
                            <div>
                              <div className="text-sm font-medium">
                                {channel.name}
                              </div>
                              <div className="text-xs text-gray-500">
                                {channel.topCategory}
                              </div>
                            </div>
                            <div className="flex text-md font-medium">
                              {" "}
                              {channel.amount}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {isLoading && <SmalLoadingIndicator />}
                  {topWordsSize === 0 && !isLoading && (
                    <div className="mt-4 flex flex-col space-y-4">
                      <div className="flex w-full items-center justify-between px-6">
                        <div>
                          <div className="text-md  text-primaryColor font-medium">
                            No channel data
                          </div>
                          <div className="text-gray-600 text-sm">
                            No non-inclusive words have been used during the
                            selected time period
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* <PeopleTable
              startDate={startDate}
              endDate={endDate}
              getReportingMemberData={getMemberReportingData}
              memberData={memberData}
              isLoading={isLoading}
            /> */}
          </div>
        </div>
      </div>
    );
  }
};

export default Reporting;
