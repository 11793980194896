import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import WithNavigation from "../components/Navigation";
import Loading from "../components/Loading";
import { KeyIcon } from "@heroicons/react/24/outline";

const NoAccessHandlerBlock = () => {
  return (
    <div className="max-w-7xl relative mx-auto px-4 sm:px-6 md:px-8">
      <div className="w-full flex items-center content-center justify-center animate-enter">
        <div className="max-w-3xl flex items-center content-center justify-center">
          <div className="text-center flex flex-col  p-8 justify-center content-center items-center rounded-lg mt-48 border bg-white/60 shadow-sm backdrop-blur border-gray-300">
            <div className="rounded-md mr-3 w-12 h-12 bg-white flex items-center content-center justify-center p-1 shadow ring-1 ring-slate-900/5">
              <KeyIcon className="h-9 w-9 text-gray-500" aria-hidden="true" />
            </div>
            <h3 className="mt-2 text-xl font-semibold text-gray-900">
              Sorry you are not authorized as an admin or handler user.
            </h3>
            <p className="mt-1 text-md text-gray-500">
              Please contact your workspace admin to request access.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProtectedRoute = ({ children, checkAdmin, checkHandler, wizard }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdminAuth, setIsAdminAuth] = useState(false);
  const [isHandlerAuth, setIsHandlerAuth] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/user/current", { withCredentials: true })
      .then(async (res) => {
        if (res.data.user) {
          setIsLoading(false);
          setIsAuthenticated(true);
          if (res.data.user.roles.includes("admin")) {
            setIsAdminAuth(true);
          }
          if (res.data.user.roles.includes("handler")) {
            setIsHandlerAuth(true);
          }
        } else {
          setIsLoading(false);
          setIsAuthenticated(false);
          setIsAdminAuth(false);
          setIsHandlerAuth(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsAuthenticated(false);
        setIsAdminAuth(false);
        setIsHandlerAuth(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated && !isLoading) {
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticated && !isLoading && wizard) {
    return children;
  }

  if (
    isAuthenticated &&
    !isLoading &&
    !checkAdmin &&
    !checkHandler &&
    !wizard
  ) {
    return <WithNavigation>{children}</WithNavigation>;
  }

  if (isAuthenticated && !isLoading && checkAdmin && !checkHandler && !wizard) {
    if (isAdminAuth) {
      return <WithNavigation>{children}</WithNavigation>;
    } else {
      return (
        <WithNavigation>
          <NoAccessHandlerBlock />
        </WithNavigation>
      );
    }
  }

  if (isAuthenticated && !isLoading && checkHandler && !checkAdmin && !wizard) {
    if (isAdminAuth || isHandlerAuth) {
      return <WithNavigation>{children}</WithNavigation>;
    } else {
      return (
        <WithNavigation>
          <NoAccessHandlerBlock />
        </WithNavigation>
      );
    }
  }
};

export default ProtectedRoute;
