import { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DateRangeSelector = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  updateDateRange,
  workspace,
}) => {
  const [dateRange, setDateRange] = useState("all");

  useEffect(() => {
    if (dateRange === "today") {
      setStartDate(moment().toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "yesterday") {
      setStartDate(moment().subtract(1, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "7d") {
      setStartDate(moment().subtract(7, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "30d") {
      setStartDate(moment().subtract(30, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "3m") {
      setStartDate(moment().subtract(90, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "6m") {
      setStartDate(moment().subtract(180, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "1y") {
      setStartDate(moment().subtract(365, "days").toDate());
      setEndDate(moment().toDate());
    } else if (dateRange === "all") {
      setStartDate(moment(workspace.create_date).toDate());
      setEndDate(moment().toDate());
    }
    if (updateDateRange) {
      updateDateRange(dateRange);
    }
  }, [dateRange]);

  return (
    <div className="w-full md:w-full min-w-300">
      <div className="flex rounded-md" role="group">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  dateRange === "custom"
                    ? "bg-primaryColor/20 text-primaryColor"
                    : "bg-gray-50",
                  "py-2 px-4 text-sm transition-all duration-300 font-medium text-mutedTextColor rounded-l-xl shadow hover:bg-primaryColor/10  hover:text-primaryColor/80"
                )}
              >
                <CalendarIcon
                  className="float-left h-4 w-4 mr-1 mt-0.5"
                  aria-hidden="true"
                />
                Custom
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 mt-3 w-screen max-w-sm transform px-4 sm:px-0 lg:max-w-md">
                  <div className="overflow-hidden rounded-md shadow-sm ring-1 ring-black ring-opacity-5">
                    <div className="bg-gray-50 p-7">
                      <h5 className="text-md font-medium mb-4 leading-relaxed">
                        Select a custom date range
                      </h5>
                      <div className="w-full md:w-full">
                        <div className="flex">
                          <div className="w-1/2 pr-4">
                            <h5 className="text-sm font-medium mb-2 text-gray-500 leading-relaxed">
                              Start date
                            </h5>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => {
                                setStartDate(date);
                                setDateRange("custom");
                              }}
                              startDate={startDate}
                              endDate={endDate}
                              selectsStart
                              dateFormat="dd.MM.yyyy"
                              renderCustomHeader={({
                                date,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div className="flex items-center justify-between px-2 py-2">
                                  <span className="text-lg text-gray-700">
                                    {format(date, "MMMM yyyy")}
                                  </span>

                                  <div className="space-x-2">
                                    <button
                                      onClick={decreaseMonth}
                                      disabled={prevMonthButtonDisabled}
                                      type="button"
                                      className={`
          ${prevMonthButtonDisabled && "cursor-not-allowed opacity-50"}
          inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
      `}
                                    >
                                      <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                                    </button>

                                    <button
                                      onClick={increaseMonth}
                                      disabled={nextMonthButtonDisabled}
                                      type="button"
                                      className={`
          ${nextMonthButtonDisabled && "cursor-not-allowed opacity-50"}
          inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
      `}
                                    >
                                      <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                          <div className="w-1/2 pl-4">
                            <h5 className="text-sm font-medium  mb-2 text-gray-500 leading-relaxed">
                              End date
                            </h5>
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => {
                                setEndDate(date);
                                setDateRange("custom");
                              }}
                              startDate={startDate}
                              endDate={endDate}
                              selectsEnd
                              dateFormat="dd.MM.yyyy"
                              minDate={startDate}
                              maxDate={Date.now()}
                              renderCustomHeader={({
                                date,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div className="flex items-center justify-between cursor-pointer px-2 py-2">
                                  <span className="text-lg text-gray-700">
                                    {format(date, "MMMM yyyy")}
                                  </span>

                                  <div className="space-x-2">
                                    <button
                                      onClick={decreaseMonth}
                                      disabled={prevMonthButtonDisabled}
                                      type="button"
                                      className={`
          ${prevMonthButtonDisabled && "cursor-not-allowed opacity-50"}
          inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
      `}
                                    >
                                      <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                                    </button>

                                    <button
                                      onClick={increaseMonth}
                                      disabled={nextMonthButtonDisabled}
                                      type="button"
                                      className={`
          ${nextMonthButtonDisabled && "cursor-not-allowed opacity-50"}
          inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
      `}
                                    >
                                      <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        <button
          type="button"
          className={classNames(
            dateRange === "today"
              ? "bg-primaryColor/20 text-primaryColor font-bold"
              : "bg-gray-50 font-medium",
            "py-2 px-4 text-sm  transition-all duration-300 text-mutedTextColor shadow hover:bg-primaryColor/10  hover:text-primaryColor/80"
          )}
          onClick={() => setDateRange("today")}
        >
          Today
        </button>
        <button
          type="button"
          className={classNames(
            dateRange === "7d"
              ? "bg-primaryColor/20 text-primaryColor font-bold"
              : "bg-gray-50 font-medium",
            "py-2 px-4 text-sm transition-all duration-300 text-mutedTextColor shadow hover:bg-primaryColor/10  hover:text-primaryColor/80"
          )}
          onClick={() => setDateRange("7d")}
        >
          7D
        </button>
        <button
          type="button"
          className={classNames(
            dateRange === "30d"
              ? "bg-primaryColor/20 text-primaryColor font-bold"
              : "bg-gray-50 font-medium",
            "py-2 px-4 text-sm transition-all duration-300  text-mutedTextColor shadow hover:bg-primaryColor/10  hover:text-primaryColor/80"
          )}
          onClick={() => setDateRange("30d")}
        >
          30D
        </button>
        <button
          type="button"
          className={classNames(
            dateRange === "6m"
              ? "bg-primaryColor/20 text-primaryColor font-bold"
              : "bg-gray-50 font-medium",
            "py-2 px-4 text-sm transition-all duration-300 text-mutedTextColor shadow hover:bg-primaryColor/10  hover:text-primaryColor/80"
          )}
          onClick={() => setDateRange("6m")}
        >
          6M
        </button>
        <button
          type="button"
          className={classNames(
            dateRange === "1y"
              ? "bg-primaryColor/20 text-primaryColor font-bold"
              : "bg-gray-50 font-medium",
            "py-2 px-4 text-sm  transition-all duration-300 text-mutedTextColor shadow hover:bg-primaryColor/10  hover:text-primaryColor/80"
          )}
          onClick={() => setDateRange("1y")}
        >
          1Y
        </button>
        <button
          type="button"
          className={classNames(
            dateRange === "all"
              ? "bg-primaryColor/20 text-primaryColor font-bold"
              : "bg-gray-50 font-medium",
            "py-2 px-4 text-sm transition-all duration-300 text-mutedTextColor rounded-r-xl shadow hover:bg-primaryColor/10  hover:text-primaryColor/80"
          )}
          onClick={() => setDateRange("all")}
        >
          All
        </button>
      </div>
    </div>
  );
};

export default DateRangeSelector;
