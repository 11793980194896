const Step1 = (props) => {
  return (
    <div className="w-full border space-x-8 border-gray-200  flex bg-white/50 content-center rounded-lg shadow-sm py-10 px-10 xs:max-w-sm animate-fade">
      <div className="w-1/2 flex  mb-4">
        <img
          src={process.env.PUBLIC_URL + "/elephant.png"}
          alt="Dara"
          className="h-72 w-auto"
        />
      </div>
      <div className="w-3/4 flex-col items-center justify-center align-middle">
        <h1 className="text-2xl font-medium mb-4 mt-6 text-black font-rubik">
          Hi there, I'm Dara 👋
        </h1>
        <p className="text-light">
          Dara is a friendly app for Slack that empowers organizations to
          enhance inclusiveness through improved daily communication and lessons
          from missteps – All inside Slack. This wizard will ask you a few
          questions to get you started and then help you setup Dara for your
          organization.
        </p>
      </div>
    </div>
  );
};

export default Step1;
