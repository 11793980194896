import { useEffect, useRef, useState } from "react";
import { state, useSnapshot, getWorkspace } from "../../state/stateStore";
import { useReward } from "react-rewards";
import {
  ExclamationCircleIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  InformationCircleIcon,
  HashtagIcon,
} from "@heroicons/react/24/outline";

import { Player } from "@lottiefiles/react-lottie-player";
import { SubscribeReportingBlock } from "../Helpers/Helpers";
import moment from "moment";
import * as api from "../../services/api";

const Dashboard = () => {
  const { reward } = useReward("rewardId", "emoji", {
    emoji: ["🌈", "🏳️‍🌈", "🦄"],
    zIndex: 9999999,
    elementCount: 3,
    startVelocity: 15,
    spread: 80,
    elementSize: 45,
  });

  const [reportingData, setReportingData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const startDate = moment().subtract(1, "month").toDate();
      const endDate = moment().toDate();
      const data = await api.getReportingData(startDate, endDate);
      setReportingData(data.data);
    };
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "r") {
        event.preventDefault();
        reward();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getWorkspace();
    };
    fetchData();
  }, []);

  const snap = useSnapshot(state);
  const user = snap.user;
  const workspace = snap.workspace;

  const occurancePercentage = Math.round(
    (workspace.occurance_counter / process.env.REACT_APP_FREE_TIER_RESPONSES) *
      100
  );

  let progressBarColor = "bg-green-500";
  if (occurancePercentage > 50 && occurancePercentage <= 75) {
    progressBarColor = "bg-yellow-500";
  } else if (occurancePercentage > 75) {
    progressBarColor = "bg-red-500";
  }

  const playerRef = useRef(null);

  // Get first name of user from user_name
  const firstName = user.user_name.split(" ")[0];

  // Calculate top channels in occurances and get channel names from workspace.channels
  let topChannels = {};
  if (
    workspace.subscription.plan !== "freemium" &&
    workspace.subscription.plan !== "react" &&
    reportingData
  ) {
    topChannels = reportingData.occurances.current.reduce((acc, cur) => {
      if (acc[cur.channel_id]) {
        acc[cur.channel_id] += 1;
      } else {
        acc[cur.channel_id] = 1;
      }
      return acc;
    }, {});

    if (Object.keys(topChannels).length > 0) {
      //Replace channel_id with channel name and sort by amount
      topChannels = Object.keys(topChannels).map((channel) => {
        const channelName = workspace.channels.find(
          (c) => c.id === channel
        ).name;
        const channelId = workspace.channels.find((c) => c.id === channel).id;
        return {
          id: channelId,
          name: "#" + channelName,
          amount: topChannels[channel],
        };
      });
      topChannels.sort((a, b) => b.amount - a.amount);
    }

    // Get top category for each  topChannel from occurances

    if (topChannels && topChannels.length > 0) {
      topChannels = topChannels.map((channel) => {
        const topCategory = reportingData.occurances.current
          .filter((occurance) => occurance.channel_id === channel.id)
          .reduce((acc, cur) => {
            cur.words.forEach((word) => {
              word.categories.forEach((category) => {
                if (acc[category]) {
                  acc[category] += 1;
                } else {
                  acc[category] = 1;
                }
              });
            });
            return acc;
          }, {});

        const topCategoryName = Object.keys(topCategory).reduce((a, b) =>
          topCategory[a] > topCategory[b] ? a : b
        );

        return {
          ...channel,
          topCategory: topCategoryName,
        };
      });
    }
  }

  return (
    <div className="max-w-7xl mx-auto px-4 mb-8 sm:px-6 md:px-8">
      <div>
        <div className="relative mt-8 mb-10">
          <div
            className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
            aria-hidden="true"
          ></div>
          <div className="relative">
            <h1 className="text-2xl text-primaryColor font-semibold font-rubik mb-1">
              Dashboard
            </h1>
            <p className="text-gray-600 text-md">
              Hey {firstName} 👋 welcome back.
            </p>
          </div>
          <span id="rewardId" style={{ zIndex: 99999 }} />
        </div>
      </div>
      {workspace.subscription.plan === "freemium" && (
        <div className="w-full mt-4">
          <div className="px-10 relative py-8 h-full overflow-hidden bg-white/70 backdrop-blur-sm rounded-lg animate-enter shadow-sm transition-all  border border-gray-200">
            <div className="relative flex gap-8 mb-2">
              <div className="w-1/2">
                <h2 className="mb-3 text-gray-800 font-medium text-2xl">
                  You are currently on freemium plan
                </h2>
                {workspace.occurance_counter ===
                  process.env.REACT_APP_FREE_TIER_RESPONSES && (
                  <div className="flex items-center mb-5 space-x-4 bg-primaryColor/20 p-3 rounded-lg">
                    <div className="w-1/3">
                      <ExclamationCircleIcon className="h-10 w-10 text-red-700 " />
                    </div>
                    <p className="text-md text-red-700 leading-6">
                      You have reached your free limit of{" "}
                      {process.env.REACT_APP_FREE_TIER_RESPONSES} responses per
                      month. After you reached the limit, there has been
                      non-inclusive words detected in your workspace. Please
                      upgrade to a paid plan so that Dara can continue educating
                      your people.
                    </p>
                  </div>
                )}
                {workspace.occurance_counter < 50 && (
                  <p className="mb-5 text-md">
                    You can use {process.env.REACT_APP_FREE_TIER_RESPONSES} free
                    responses per month. If you want to use more responses, you
                    can upgrade to a paid plan. Paid plans also include more
                    features like reporting, analytics, and more.
                  </p>
                )}
              </div>
              <div className="w-1/2">
                {workspace.occurance_counter < 50 && (
                  <div className="w-full">
                    <div className="flex justify-between w-full items-center mb-4">
                      <h3 className="text-lg font-medium text-gray-900">
                        Free responses used
                      </h3>
                      <div className="flex">
                        <span className="text-lg font-medium text-gray-900">
                          {workspace.occurance_counter} /{" "}
                          {process.env.REACT_APP_FREE_TIER_RESPONSES}
                        </span>
                      </div>
                    </div>
                    <div className="w-full mt-1 bg-gray-300 rounded-full h-3">
                      <div
                        className={progressBarColor + " h-3 rounded-full"}
                        style={{ width: occurancePercentage + "%" }}
                      ></div>
                    </div>
                  </div>
                )}
                <form method="POST" action="/billing/billing-portal">
                  <button
                    type="submit"
                    onMouseEnter={() => {
                      playerRef.current.play();
                    }}
                    onMouseLeave={() => {
                      playerRef.current.stop();
                    }}
                    className="group float-right w-full  mt-6 px-6 py-3 rounded-lg overflow-hidden hover:scale-105 transition ease-in-out duration-500 bg-black"
                  >
                    <span className="relative z-0 text-white font-semibold flex items-center justify-center group-hover:text-white">
                      <div>Upgrade your plan</div>
                      <Player
                        src="https://assets3.lottiefiles.com/packages/lf20_eXccgKjr0V.json"
                        hover={true}
                        background="transparent"
                        rendererSettings={{
                          preserveAspectRatio: "xMidYMid slice",
                        }}
                        ref={playerRef}
                        style={{ width: 35, height: 35, marginTop: -8 }}
                      />
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <h2 className="text-xl text-gray-900 font-semibold font-rubik mb-1 mt-8">
        Last 30 days
      </h2>
      <div className="relative">
        {workspace.subscription.plan === "freemium" && (
          <SubscribeReportingBlock />
        )}
        <div
          className={workspace.subscription.plan === "freemium" ? "blur" : ""}
        >
          <div className="grid mt-4 grid-cols-1 gap-8 sm:grid-cols-4 lg:grid-cols-4">
            <div className="px-10 h-[17rem]  overflow-hidden bg-[#fff1f0] bg-gradient-to-br from-rose-50/30 to-rose-100/30 backdrop-blur-sm rounded-lg items-center flex flex-col text-center justify-center animate-enter shadow-sm border border-primaryColor/50 transition-all">
              <div className="text-7xl text-primaryColor font-semibold">
                {workspace.word_count}
              </div>
              <h3 className="text-lg mt-2 font-medium text-gray-800">
                Non-inclusive words
              </h3>
            </div>
            <div className="px-10 h-[17rem] overflow-hidden bg-emerald-50  bg-gradient-to-br from-emerald-50/50 to-emerald-100/50 backdrop-blur-sm rounded-lg flex flex-col items-center text-center justify-center border border-emerald-200 animate-enter shadow-sm transition-all">
              <div className="text-7xl text-emerald-600 font-semibold">
                {workspace.incident_count}
              </div>
              <h3 className="text-lg mt-2 font-medium text-gray-800">
                Reported incidents
              </h3>
            </div>
            <div className="overflow-hidden col-span-2 bg-white/40 backdrop-blur-lg rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
              <div className="relative px-6 py-6 justify-between flex items-center">
                <div className="flex items-center space-x-3">
                  <InformationCircleIcon className="w-10 h-10" />
                  <div className="flex flex-col">
                    <h3 className="text-md font-medium text-gray-900">
                      Insights
                    </h3>
                    <p className="text-sm text-gray-500">
                      Insights about your workspace and people
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200 ">
                <div className="px-6 py-6">
                  <p className="text-xl text-gray-900">
                    In the last 30 days, there has been{" "}
                    <span className="font-bold">{workspace.word_count}</span>{" "}
                    non-inclusive words detected in your workspace. The most
                    common category of words used is{" "}
                    <span className="font-bold">
                      {workspace.category_count[0]
                        ? workspace.category_count[0].category
                        : "N/A"}
                    </span>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden col-span-2 bg-white/40 backdrop-blur-lg rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
              <div className="relative px-6 py-6 justify-between flex items-center">
                <div className="flex items-center space-x-3">
                  <ChatBubbleOvalLeftEllipsisIcon className="w-10 h-10" />
                  <div className="flex flex-col">
                    <h3 className="text-md font-medium text-gray-900">
                      Common words
                    </h3>
                    <p className="text-sm text-gray-500">
                      Words that are used the most
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200">
                {workspace.top_words.length > 0 && (
                  <div className="mt-2 px-6 flex flex-col divide-y divide-gray-200">
                    {workspace.top_words.map((word) => (
                      <div className="flex w-full py-4 items-center justify-between">
                        <div className="w-3/4">
                          <div className="text-md font-medium">{word._id}</div>
                          <div className="text-gray-500 text-sm">
                            {word.categories.map((category) => (
                              <span className="mr-1">{category}</span>
                            ))}
                          </div>
                        </div>
                        <div className="flex text-md font-medium">
                          {word.count} times used
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {workspace.top_words.length === 0 && (
                  <div className="w-full py-4 pt-24 text-center">
                    <div className="text-lg font-medium">No data yet..</div>
                  </div>
                )}
              </div>
            </div>
            <div className="overflow-hidden col-span-2 bg-white rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
              <div className="relative px-6 py-6 justify-between flex items-center">
                <div className="flex items-center space-x-3">
                  <HashtagIcon className="w-8 h-8" />
                  <div className="flex flex-col">
                    <h3 className="text-md font-medium text-gray-800">
                      Common channels
                    </h3>
                    <p className="text-sm text-gray-500">
                      Channels with most non-inclusive language
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200">
                {Object.keys(topChannels).length > 0 && (
                  <div className="mt-2 px-6 flex flex-col divide-y divide-gray-200">
                    {topChannels.map((channel) => (
                      <div className="flex w-full py-4 items-center justify-between">
                        <div>
                          <div className="text-md font-medium">
                            {channel.name}
                          </div>
                          <div className="text-sm text-gray-500">
                            {channel.topCategory}
                          </div>
                        </div>
                        <div className="flex text-md font-medium">
                          {" "}
                          {channel.amount}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
