import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
  Text,
} from "recharts";
import { categories } from "../Helpers/Helpers";

import { SmalLoadingIndicator } from "../Helpers/LoadingComponents";

function renderPolarAngleAxis({ payload, x, y, cx, cy, ...rest }) {
  return (
    <Text
      {...rest}
      fontSize={12}
      verticalAnchor="middle"
      y={y + (y - cy) / 12}
      x={x + (x - cx) / 12}
    >
      {payload.value}
    </Text>
  );
}

const RadarCategoriesChart = ({ categoriesData, isLoading }) => {
  const allCategories = categories.map((category) => category.name);

  const categoriesDataWithAllCategories = allCategories.map((category) => {
    const categoryData = categoriesData.find(
      (categoryData) => categoryData.category === category
    );
    if (categoryData) {
      return categoryData;
    } else {
      return {
        category: category,
        amount: 0,
      };
    }
  });

  return (
    <div className="w-full min-h-full bg-white rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
      <div className="relative px-6 py-6 justify-between flex items-center">
        <div className="flex items-center space-x-3">
          <div className="flex flex-col">
            <h3 className="text-lg font-medium text-gray-900">Language traits</h3>
            <p className="text-sm text-gray-500">
              Type of non-inclusive language used by this person
            </p>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200">
        {isLoading && (
          <div className="flex items-center justify-center h-[300px]">
            <SmalLoadingIndicator />
          </div>
        )}
        {categoriesData.length === 0 && !isLoading && (
          <div className="text-gray-600 flex flex-col items-center content-center justify-center text-center h-[300px] px-8 text-md">
            <div>
              Not enough data. But hey,{" "}
              <span className="text-green-600">that's a good thing!</span>{" "}
              <br /> Please try another date range.
            </div>
          </div>
        )}
        {categoriesData.length > 0 && !isLoading && (
          <ResponsiveContainer width="100%" height={400}>
            <RadarChart
              cx="50%"
              cy="50%"
              outerRadius="75%"
              data={categoriesDataWithAllCategories}
            >
              <defs>
                <linearGradient id="colorAll" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#BA7C7C" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#ffa8a8" stopOpacity={0.25} />
                </linearGradient>
              </defs>
              <PolarGrid gridType="circle" />
              <PolarAngleAxis
                dataKey="category"
                tick={(props) => renderPolarAngleAxis(props)}
              />
              <Radar
                name="Amount"
                dataKey="amount"
                fillOpacity={1}
                fill="url(#colorAll)"
                stroke="#BA7C7C"
              />
            </RadarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default RadarCategoriesChart;
