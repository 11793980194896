/*                                */
/*     State store               */
/*                              */

import { proxy, useSnapshot, subscribe } from "valtio";
import * as api from "../services/api";

const state = proxy({
  user: null,
  workspace: null,
});

const getUser = async () => {
  let data = await api.getUser();
  if (data.user) {
    state.user = data.user;
  } else {
    state.user = null;
  }
};

const getWorkspace = async () => {
  let data = await api.getWorkspace();
  if (data.workspace) {
    state.workspace = data.workspace;
  } else {
    state.workspace = null;
  }
};

export { state, useSnapshot, subscribe, getUser, getWorkspace };
