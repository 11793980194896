import { Wizard } from "react-use-wizard";

// Steps and footer
import Footer from "./Footer";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";

const WizardBase = () => {
  return (
    <div className="h-screen flex bg-gray-50">
      <div className="absolute -top-[45rem] z-0 opacity-50 blur-lg overflow-hidden">
        <img
          src={process.env.PUBLIC_URL + "/beam.png"}
          className="w-[60rem] object-cover object-center"
          alt="Rainbow beam"
        />
      </div>
      <div className="w-full max-w-4xl m-auto z-50">
        <Wizard startIndex={0} footer={<Footer />}>
          <Step1 />
          <Step2 />
          <Step3 />
          <Step4 />
        </Wizard>
      </div>
    </div>
  );
};

export default WizardBase;
