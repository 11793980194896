import { Transition, Listbox } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { SmalLoadingIndicator } from "../Helpers/LoadingComponents";
import axios from "axios";
import { set } from "mongoose";

const ChannelSelector = () => {
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchChannels = async () => {
      setLoading(true);
      const res = await fetch("/api/workspace/channels");
      let data = await res.json();

      // Get channels that Dara is already in and set them as selected
      const isMemberChannels = data.data.filter((channel) => channel.is_member);
      setSelectedChannels(isMemberChannels);

      // Sort channels alphabetically by name
      data.data.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setChannels(data.data);
      setLoading(false);
    };
    fetchChannels();
  }, []);

  const addToSelectedChannels = async () => {
    setLoading(true);
    const res = await axios.post("/api/workspace/channels", {
      channels: selectedChannels,
    });
    if (res.status === 200) {
      setLoading(false);
      setSuccess(true);
    } else {
      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <div>
      {!success && (
        <>
          <Listbox
            value={selectedChannels}
            onChange={setSelectedChannels}
            multiple
            className="z-50"
          >
            <div className="z-50 mt-1 w-full">
              <Listbox.Button className="relative w-full mt-4 cursor-default rounded-lg border  border-gray-300 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-primaryColor focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryColor sm:text-sm">
                {loading && (
                  <div className="flex">
                    <SmalLoadingIndicator />
                  </div>
                )}
                {!loading &&
                  selectedChannels
                    .map((channel) => "#" + channel.name)
                    .join(", ")}
                {selectedChannels.length === 0 && !loading && (
                  <span className="text-gray-500">
                    Select channels to add Dara to
                  </span>
                )}
                {!loading && (
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                )}
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="mt-1 h-48 z-50 overflow-scroll rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {channels.length > 0 &&
                    channels.map((channel, channelIdx) => (
                      <Listbox.Option
                        key={channelIdx}
                        className={({ active }) =>
                          `relative cursor-pointer z-50 select-none py-2 pl-10 pr-4 ${
                            active
                              ? "bg-primaryColor/10 text-primaryColor"
                              : "text-gray-900"
                          }`
                        }
                        value={channel}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              #{channel.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primaryColor">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
          {selectedChannels.length > 0 && (
            <div className="mt-4">
              <button
                type="submit"
                onClick={() => addToSelectedChannels()}
                disabled={loading}
                className="w-full bg-black text-white rounded-lg py-2 px-4 text-sm font-medium shadow-sm hover:shadow-lg hover:shadow-black/20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primaryColor focus:ring-white"
              >
                Add Dara to {selectedChannels.length}{" "}
                {selectedChannels.length === 1 ? "channel" : "channels"}
              </button>
            </div>
          )}
        </>
      )}
      {success && (
        <div className="flex mt-6 space-x-4 justify-start items-center">
          <CheckCircleIcon
            className="h-20 w-20 text-green-500"
            aria-hidden="true"
          />
          <div>
            <h1 className="text-xl font-medium text-black">Success!</h1>
            <p className="text-light text-sm">
              Dara has been added to the channels you selected and removed from
              the channels you de-selected.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChannelSelector;
