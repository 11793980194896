import { useWizard } from "react-use-wizard";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { getUser, getWorkspace } from "../../state/stateStore";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Footer = (props) => {
  const navigate = useNavigate();
  const {
    nextStep,
    previousStep,
    isLoading,
    activeStep,
    stepCount,
    goToStep,
    isLastStep,
    isFirstStep,
  } = useWizard();

  return (
    <div className="flex justify-between items-center mt-6">
      {!isFirstStep && (
        <button
          className="text-sm text-primaryColor w-12 font-normal py-2 px-4 rounded"
          onClick={() => previousStep()}
          disabled={isLoading || isFirstStep}
        >
          Previous
        </button>
      )}
      {isFirstStep && <div></div>}
      <nav className="z-1">
        <ol className="flex items-center z-0">
          {[...Array(stepCount)].map((value, stepIdx) => (
            <li
              key={stepIdx}
              className={classNames(
                stepIdx !== stepCount - 1 ? "pr-8 sm:pr-20" : "",
                "relative z-0"
              )}
            >
              {activeStep > stepIdx ? (
                <>
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="h-0.5 w-full bg-primaryColor" />
                  </div>
                  <button
                    onClick={() => goToStep(stepIdx)}
                    className="relative flex h-8 w-8 items-center cursor-pointer justify-center rounded-full bg-primaryColor"
                  >
                    <CheckIcon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </>
              ) : activeStep === stepIdx ? (
                <>
                  <div
                    className="absolute inset-0 z-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <button
                    className="relative flex h-8 w-8 items-center cursor-pointer justify-center rounded-full bg-gray-50 border border-primaryColor hover:bg-gray-200"
                    aria-current="step"
                  >
                    <span
                      className="h-2.5 w-2.5 rounded-full bg-primaryColor"
                      aria-hidden="true"
                    />
                  </button>
                </>
              ) : (
                <>
                  <div
                    className="absolute inset-0 z-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <button className="group relative flex h-8 w-8 items-center cursor-pointer justify-center rounded-full border-2 border-gray-300 bg-gray-50 hover:border-primaryColor">
                    <span
                      className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-primaryColor"
                      aria-hidden="true"
                    />
                  </button>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
      {!isLastStep && activeStep !== 1 && activeStep !== 2 && (
        <button
          className="flex cursor-pointer float-right justify-center items-center transition-all duration-100 ease-in hover:shadow-lg hover:shadow-black/20 rounded-md py-2 px-4 text-sm font-medium text-white shadow-sm bg-black focus:outline-none focus:border-none focus:ring-2 focus:ring-primaryColor focus:ring-offset-2"
          onClick={() => nextStep()}
          disabled={isLoading || isLastStep}
        >
          Next
        </button>
      )}{" "}
      {activeStep === 1 && (
        <button
          className="flex cursor-pointer float-right justify-center items-center transition-all duration-100 ease-in hover:shadow-lg hover:shadow-black/20 rounded-md py-2 px-4 text-sm font-medium text-white shadow-sm bg-black focus:outline-none focus:border-none focus:ring-2 focus:ring-primaryColor focus:ring-offset-2"
          type="submit"
          form="org-form"
        >
          Next
        </button>
      )}
      {activeStep === 2 && (
        <button
          className="flex cursor-pointer float-right justify-center items-center transition-all duration-100 ease-in hover:shadow-lg hover:shadow-black/20 rounded-md py-2 px-4 text-sm font-medium text-white shadow-sm bg-black focus:outline-none focus:border-none focus:ring-2 focus:ring-primaryColor focus:ring-offset-2"
          type="submit"
          form="admin-form"
        >
          Next
        </button>
      )}
      {isLastStep && (
        <button
          className="flex cursor-pointer float-right justify-center items-center transition-all duration-100 ease-in hover:shadow-lg hover:shadow-black/20 rounded-md py-2 px-4 text-sm font-medium text-white shadow-sm bg-black focus:outline-none focus:border-none focus:ring-2 focus:ring-primaryColor focus:ring-offset-2"
          onClick={async () => {
            await getUser();
            await getWorkspace();
            navigate("/dashboard");
          }}
          disabled={isLoading}
        >
          Finish
        </button>
      )}
    </div>
  );
};

export default Footer;
