import { state, useSnapshot } from "../../state/stateStore";
import { TrashIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Settings = () => {
  const snap = useSnapshot(state);
  const currentUser = snap.user;

  const navigate = useNavigate();

  if (currentUser) {
    return (
      <div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="container mx-auto my-8">
            <div className="relative mt-8 mb-10">
              <div className="relative">
                <h1 className="text-2xl text-primaryColor font-semibold font-rubik mb-1">
                  Settings
                </h1>
                <p className="text-gray-600 text-md">
                  Change Dara settings here
                </p>
              </div>
            </div>
            <div className="p-5 mt-6 max-w-4xl shadow rounded-lg bg-white animate-fade">
              <div>
                <div>
                  <h2 className="text-lg leading-6 font-medium text-primaryColor">
                    General
                  </h2>
                  <p className="mt-1 text-sm text-gray-900">
                    You can change Dara related settings here.
                  </p>
                </div>
                <ul role="list" className="mt-2 divide-y divide-gray-200">
                  <li className="py-4 flex items-center justify-between">
                    <div className="flex space-x-4">
                      <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-primaryColor/10 items-center justify-center flex ">
                        <TrashIcon
                          className="h-6 w-6 text-primaryColor"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-medium text-gray-900">
                          Request data deletion
                        </p>
                        <p className="text-sm text-gray-600">
                          If you want to delete your personal data, send us a
                          request.
                        </p>
                      </div>
                    </div>
                    <a
                      href="mailto:aleksi@evergreen.so?subject=Dara app data deletion request"
                      target="_blank"
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-normal text-sm rounded-lg text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                    >
                      Request
                    </a>
                  </li>
                  <li className="py-4 flex items-center justify-between">
                    <div className="flex space-x-4">
                      <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-primaryColor/10 items-center justify-center flex ">
                        <SparklesIcon
                          className="h-6 w-6 text-primaryColor"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-medium text-gray-900">
                          Restart wizard
                        </p>
                        <p className="text-sm text-gray-600">
                          You can go through the onboarding wizard here.
                        </p>
                      </div>
                    </div>
                    <a
                      onClick={() => navigate("/wizard")}
                      className="inline-flex items-center cursor-pointer px-2.5 py-1.5 border border-transparent text-sm leading-4 font-normal text-sm rounded-lg text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                    >
                      Go to wizard
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="p-5 mt-6 max-w-4xl shadow rounded-lg bg-white animate-fade">
              <div>
                <div>
                  <h2 className="text-lg leading-6 font-medium text-primaryColor">
                    Resources
                  </h2>
                  <p className="mt-1 text-sm text-gray-900">
                    Various resources for your Slack workspace to help you with
                    your DEI journey.
                  </p>
                </div>
                <ul role="list" className="mt-2 divide-y divide-gray-200">
                  <li className="py-4 flex items-center justify-between">
                    <div className="flex space-x-4">
                      <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-primaryColor/10 items-center justify-center flex ">
                        <div className="text-2xl">🌈</div>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-medium text-gray-900">
                          Emoji pack
                        </p>
                        <p className="text-sm text-gray-600">
                          a LGBTQ+ Emojipack for your Slack workspace.
                        </p>
                      </div>
                    </div>
                    <a
                      href="https://www.customemoji.com/category/lgbtq"
                      target="_blank"
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-normal text-sm rounded-lg text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                    >
                      View emojis
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Settings;
