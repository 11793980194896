import { LockClosedIcon } from "@heroicons/react/24/outline";
import { Player } from "@lottiefiles/react-lottie-player";
import React, { useRef } from "react";

export const categories = [
  {
    name: "Ableist Language",
    description:
      "Phrases marginalizing individuals with disabilities, often through belittling or pejorative terms.",
  },
  {
    name: "Ageist Language",
    description:
      "Dismissive language that perpetuates stereotypes, unfairly judges, or discriminates against people due to their age.",
  },
  {
    name: "Gendered Language",
    description:
      "Perpetuating stereotypes by assigning specific roles, traits, or expectations based on one’s gender.",
  },
  {
    name: "Homophobic Language",
    description:
      "Demeaning or derogatory terms targeting individuals based on their sexual orientation, specifically towards LGBTQ+ communities.",
  },
  {
    name: "Racist Language",
    description:
      "Words or expressions reinforcing racial prejudices, stereotypes, or discrimination against certain ethnic groups.",
  },
  {
    name: "Sexist Language",
    description:
      "Words perpetuating inequality or stereotypes, diminishing or objectifying individuals based on their sex or gender.",
  },
  {
    name: "Sizeist Language",
    description:
      "Insensitive phrases that belittle, judge, or demean individuals based on their body size or weight.",
  },
  {
    name: "Transphobic Language",
    description:
      "Terminology that marginalizes, invalidates, or discriminates against transgender or nonbinary individuals.",
  },
  {
    name: "Xenophobic Language",
    description:
      "Discriminatory expressions aimed at denigrating individuals or groups from different countries, cultures, or ethnic backgrounds.",
  },
  {
    name: "Other",
    description:
      "Other category includes words that doesn't go to any other listed category",
  },
];

export const incidentCategories = [
  {
    id: 1,
    name: "Bias in decision-making",
    description:
      "Incidents in which unconscious or conscious biases have influenced hiring, promotion, or other work-related decisions, leading to unequal treatment or opportunities for individuals from different backgrounds.",
  },
  {
    id: 2,
    name: "Discrimination",
    description:
      "Incidents related to unfair treatment or prejudice based on an individual's race, ethnicity, gender, age, religion, sexual orientation, disability, or other protected characteristics",
  },
  {
    id: 3,
    name: "Harassment",
    description:
      "Incidents involving unwelcome conduct, including offensive jokes, slurs, name-calling, or physical actions, based on an individual's protected characteristics.",
  },
  {
    id: 4,
    name: "Inclusive language and communication",
    description:
      "Incidents involving the use of insensitive language, offensive humor, or communication practices that marginalize or exclude certain individuals or groups.",
  },
  {
    id: 5,
    name: "Inclusive policies and practices",
    description:
      "Incidents related to organizational policies or practices that disproportionately affect certain groups, perpetuate systemic inequalities, or hinder the creation of an inclusive work environment.",
  },
  {
    id: 6,
    name: "Microaggression",
    description:
      "Subtle or indirect behaviors, comments, or actions that convey negative stereotypes, disrespect, or bias against individuals belonging to a particular group.",
  },
  {
    id: 7,
    name: "Pay inequity",
    description:
      "Incidents related to disparities in pay, benefits, or other compensation based on factors such as gender, race, or ethnicity, rather than job performance or qualifications.",
  },
  {
    id: 8,
    name: "Retaliation",
    description:
      " Incidents where employees experience adverse actions, such as demotion, termination, or exclusion from projects, after reporting a DEI incident, participating in a DEI investigation, or advocating for DEI initiatives.",
  },
  {
    id: 100,
    name: "Other",
    description: "Other",
  },
];

export function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export const SubscribeReportingBlock = () => {
  const playerRef = useRef(null);
  return (
    <div className="z-50 absolute w-full content-center flex justify-center animate-enter">
      <div className="text-center p-8 rounded-lg mt-48 border flex flex-col items-center content-center justify-center bg-white/70 shadow backdrop-blur border-gray-200">
        <div className="rounded-md mr-3 w-12 h-12 bg-white flex items-center content-center justify-center p-1 shadow ring-1 ring-slate-900/5">
          <LockClosedIcon
            className="h-9 w-9 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <h3 className="mt-2 text-xl font-semibold text-gray-900">
          Upgrade to Analyze plan to access reporting
        </h3>
        <p className="mt-1 text-md text-gray-500">
          Analyze plan includes reporting and advanced people analytics.
        </p>
        <form method="POST" action="/billing/billing-portal">
          <button
            type="submit"
            onMouseEnter={() => {
              playerRef.current.play();
            }}
            onMouseLeave={() => {
              playerRef.current.stop();
            }}
            className="group float-right w-full  mt-6 px-6 py-3 rounded-lg overflow-hidden hover:scale-105 transition ease-in-out duration-500 bg-black"
          >
            <span className="relative z-0 text-white font-semibold flex items-center justify-center group-hover:text-white">
              <div>Upgrade your plan</div>
              <Player
                src="https://assets3.lottiefiles.com/packages/lf20_eXccgKjr0V.json"
                hover={true}
                background="transparent"
                rendererSettings={{
                  preserveAspectRatio: "xMidYMid slice",
                }}
                ref={playerRef}
                style={{ width: 35, height: 35, marginTop: -8 }}
              />
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};

export const SubscribeIncidentBlock = () => {
  const playerRef = useRef(null);
  return (
    <div className="z-50 absolute w-full content-center flex justify-center animate-enter">
      <div className="text-center p-8 rounded-lg mt-48 border flex flex-col items-center content-center justify-center bg-white/70 shadow backdrop-blur border-gray-200">
        <div className="rounded-md mr-3 w-12 h-12 bg-white flex items-center content-center justify-center p-1 shadow ring-1 ring-slate-900/5">
          <LockClosedIcon
            className="h-9 w-9 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <h3 className="mt-2 text-xl font-semibold text-gray-900">
          Upgrade to React plan to access incident management
        </h3>
        <p className="mt-1 text-md text-gray-500">
          React plan includes incident reporting and management features.
        </p>
        <form method="POST" action="/billing/billing-portal">
          <button
            type="submit"
            onMouseEnter={() => {
              playerRef.current.play();
            }}
            onMouseLeave={() => {
              playerRef.current.stop();
            }}
            className="group float-right w-full  mt-6 px-6 py-3 rounded-lg overflow-hidden hover:scale-105 transition ease-in-out duration-500 bg-black"
          >
            <span className="relative z-0 text-white font-semibold flex items-center justify-center group-hover:text-white">
              <div>Upgrade your plan</div>
              <Player
                src="https://assets3.lottiefiles.com/packages/lf20_eXccgKjr0V.json"
                hover={true}
                background="transparent"
                rendererSettings={{
                  preserveAspectRatio: "xMidYMid slice",
                }}
                ref={playerRef}
                style={{ width: 35, height: 35, marginTop: -8 }}
              />
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};
