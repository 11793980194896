import { useForm } from "react-hook-form";
import { useWizard } from "react-use-wizard";
import * as api from "../../../services/api";

const Step2 = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const { nextStep } = useWizard();

  const onSubmit = async (data) => {
    // Submit questionnaire data to backend
    data.is_admin_step = true;
    await api.sendQuestionnaire(data);
    nextStep();
  };

  const watchAdminRole = watch("admin_role");
  const watchHeardFrom = watch("admin_heard_from");
  return (
    <div className="w-full border space-x-8 border-gray-200 flex bg-white/60 content-center rounded-lg shadow-sm py-8 px-10 xs:max-w-sm animate-fade">
      <div className="w-3/4 flex-col items-center justify-center align-middle">
        <form id="admin-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-xl font-medium mb-4 text-black font-rubik">
            Personal info
          </h2>
          <div className="mt-4 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="word"
                className="block text-sm font-medium text-gray-700"
              >
                Your role
              </label>
              <div className="mt-2 flex w-full rounded-lg shadow-sm">
                <select
                  type="text"
                  name="admin_role"
                  id="admin_role"
                  {...register("admin_role", { required: true })}
                  required
                  className="flex-1 focus:ring-primaryColor focus:border-primaryColor block w-full min-w-0 rounded-lg sm:text-sm border-gray-300"
                >
                  <option value="Chief DEI Officer">Chief DEI Officer</option>
                  <option value="DEI Manager">DEI Manager</option>
                  <option value="Chief Human Resources Officer">
                    Chief Human Resources Officer
                  </option>
                  <option value="Chief People Officer">
                    Chief People Officer
                  </option>
                  <option value="VP of People and Culture">
                    VP of People and Culture
                  </option>
                  <option value="People and Culture Lead">
                    People and Culture Lead
                  </option>
                  <option value="People and Culture Manager">
                    People and Culture Manager
                  </option>
                  <option value="HR Coordinator">HR Coordinator</option>
                  <option value="HR Administrator">HR Administrator</option>
                  <option value="HR Manager">HR Manager</option>
                  <option value="HR Director">HR Director</option>
                  <option value="CEO">CEO</option>
                  <option value="COO">COO</option>
                  <option value="Owner/Founder">Owner/Founder</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {errors.admin_role && (
                <span className="text-sm mt-1 text-red-500">
                  This field is required
                </span>
              )}
              {watchAdminRole === "Other" && (
                <div className="mt-2 flex w-full rounded-lg shadow-sm">
                  <input
                    type="text"
                    name="admin_role_other"
                    id="admin_role_other"
                    {...register("admin_role_other", { required: false })}
                    required
                    placeholder="Enter your role..."
                    className="flex-1 focus:ring-primaryColor focus:border-primaryColor block w-full min-w-0 rounded-lg sm:text-sm border-gray-300"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mt-4 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="word"
                className="block text-sm font-medium text-gray-700"
              >
                Where did you hear from us?
              </label>
              <div className="mt-2 flex w-full rounded-lg shadow-sm">
                <select
                  type="text"
                  name="admin_heard_from"
                  id="admin_heard_from"
                  {...register("admin_heard_from", { required: true })}
                  required
                  className="flex-1 focus:ring-primaryColor focus:border-primaryColor block w-full min-w-0 rounded-lg sm:text-sm border-gray-300"
                >
                  <option value="Slack Directory">Slack Directory</option>
                  <option value="Recommended by friend or colleague">
                    Recommended by friend or colleague
                  </option>
                  <option value="Search engine">
                    Search engine (Google, Yahoo etc.)
                  </option>
                  <option value="Social media">Social media</option>
                  <option value="Blog or publication">
                    Blog or publication
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {watchHeardFrom === "Other" && (
                <div className="mt-2 flex w-full rounded-lg shadow-sm">
                  <input
                    type="text"
                    name="admin_heard_from_other"
                    id="admin_heard_from_other"
                    {...register("admin_heard_from_other", { required: false })}
                    required
                    placeholder="Where did you hear from us?"
                    className="flex-1 focus:ring-primaryColor focus:border-primaryColor block w-full min-w-0 rounded-lg sm:text-sm border-gray-300"
                  />
                </div>
              )}
              {errors.admin_heard_from && (
                <span className="text-sm mt-1 text-red-500">
                  This field is required
                </span>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="w-1/2 flex  mb-4">
        <img
          src={process.env.PUBLIC_URL + "/elephant2.png"}
          alt="Dara"
          className="h-72 w-auto"
        />
      </div>
    </div>
  );
};

export default Step2;
