import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ChevronLeftIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  IdentificationIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import * as api from "../../services/api";
import moment from "moment";

import ResolveIncidentModal from "./ResolveModal";
import StartWorkModal from "./StartWorkModal";
import { GridPattern } from "../Helpers/GridPattern";
import { incidentCategories } from "../Helpers/Helpers";

export function HeroPattern() {
  return (
    <div className="absolute inset-0 opacity-75 z-10 mx-0 max-w-none overflow-hidden">
      <div className="absolute left-1/2 top-0 ml-[-38rem] h-[25rem] w-[81.25rem]">
        <div className="absolute inset-0 bg-gradient-to-r from-[#36b49f] to-[#DBFF75] opacity-40 [mask-image:radial-gradient(farthest-side_at_top,white,transparent)]">
          <GridPattern
            width={72}
            height={56}
            x="-12"
            y="4"
            squares={[
              [4, 3],
              [2, 1],
              [7, 3],
              [10, 6],
            ]}
            className="absolute inset-x-0 inset-y-[-50%] h-[200%] w-full skew-y-[-18deg] fill-black/40 stroke-black/50 mix-blend-overlay"
          />
        </div>
        <svg
          viewBox="0 0 1113 440"
          aria-hidden="true"
          className="absolute left-1/2 top-0 ml-[-19rem] w-[69.5625rem] fill-white blur-[26px]"
        >
          <path d="M.016 439.5s-9.5-300 434-300S882.516 20 882.516 20V0h230.004v439.5H.016Z" />
        </svg>
      </div>
    </div>
  );
}

const IncidentPage = () => {
  let navigate = useNavigate();
  const params = useParams();
  const incidentId = params.incidentId;
  const [incidentData, setIncidentData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [resolveModalOpen, setResolveModalOpen] = useState(false);
  const [startWorkModalOpen, setStartWorkModalOpen] = useState(false);

  const fetchIncident = async () => {
    setIsLoading(true);
    const incidentData = await api.getIncidentById(incidentId);
    setIncidentData(incidentData.incident);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchIncident();
  }, [incidentId]);

  useEffect(() => {
    if (!resolveModalOpen) {
      fetchIncident();
    }
  }, [resolveModalOpen]);

  useEffect(() => {
    if (!startWorkModalOpen) {
      fetchIncident();
    }
  }, [startWorkModalOpen]);

  if (incidentData && !isLoading) {
    return (
      <div className="py-6 animate-fade z-50">
        <ResolveIncidentModal
          open={resolveModalOpen}
          setOpen={setResolveModalOpen}
          incident={incidentData}
        />
        <StartWorkModal
          open={startWorkModalOpen}
          setOpen={setStartWorkModalOpen}
          incident={incidentData}
        />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <button
            onClick={() => navigate(-1)}
            className="relative flex text-sm mt-4 items-center cursor-pointer hover:text-primaryColor transition-all after:absolute after:bg-primaryColor after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300"
          >
            <ChevronLeftIcon
              className="float-left h-4 w-4 mr-1 -ml-1"
              aria-hidden="true"
            />
            Back
          </button>
          <div className="flex items-center space-x-4 mt-8">
            <div className="flex-shrink-0">
              <div className="relative">
                <div className="rounded-md bg-white p-2 shadow ring-1 ring-slate-900/5">
                  <ExclamationTriangleIcon
                    className="text-primarycolor flex-shrink-0 h-10 w-10 text-primaryColor hover:text-primaryColor transition-all"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-black">
                {incidentData.subject}
              </h1>
              <p className="text-md font-normal text-gray-900">
                {
                  incidentCategories.find(
                    (category) => category.id === parseInt(incidentData.type)
                  ).name
                }
              </p>
            </div>
            {/* Action buttons */}
            <div className="flex-grow text-right space-x-2">
              {(incidentData.status === "in_progress" ||
                incidentData.status === "open") && (
                <button
                  type="button"
                  onClick={() => setResolveModalOpen(true)}
                  className="inline-flex items-center px-3 py-1 border border-green-700 transition-all shadow-sm text-sm font-normal rounded-lg text-green-700 bg-green-100   hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
                >
                  <CheckCircleIcon
                    className="-ml-1 mr-1 h-4 w-4"
                    aria-hidden="true"
                  />
                  Resolve
                </button>
              )}
            </div>
            {/* Resolved badge, big size */}
            {incidentData.status === "resolved" && (
              <div className="flex-shrink-0">
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-md border-green-700 border font-normal bg-green-100 text-green-700">
                  <CheckCircleIcon
                    className="-ml-1 mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                  Resolved
                </span>
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 gap-6 mt-10 sm:grid-cols-2 lg:grid-cols-2">
            <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-lg">
              <div className="px-2 py-4 sm:px-6">
                <div className="flex items-center">
                  <IdentificationIcon
                    className="h-8 w-8 text-gray-900"
                    aria-hidden="true"
                  />
                  <div className="ml-4">
                    <h3 className="text-md font-semibold text-gray-900">
                      Incident details
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      Incident details and status
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200">
                <dl className="divide-y divide-gray-200">
                  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-normal text-gray-500">Type</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                      {
                        incidentCategories.find(
                          (category) =>
                            category.id === parseInt(incidentData.type)
                        ).name
                      }
                    </dd>
                  </div>
                  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-normal text-gray-500">
                      Reported by
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                      {!incidentData.anonymous && (
                        <div className="flex items-center">
                          <img
                            src={incidentData.reporter.image}
                            alt=""
                            className="w-6 h-6 rounded-full"
                          />
                          <span
                            className="ml-2 cursor-pointer text-primaryColor hover:underline transition-all"
                            onClick={() => {
                              navigate(
                                `/reporting/profile/${incidentData.reporter.user_id}`
                              );
                            }}
                          >
                            {incidentData.reporter.name}
                          </span>
                        </div>
                      )}
                      {incidentData.anonymous && (
                        <span className="px-2 inline-flex text-xs leading-5 rounded-full bg-gray-100 text-gray-800">
                          Anonymous reporter
                        </span>
                      )}
                    </dd>
                  </div>
                  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-normal text-gray-500">
                      Related to
                    </dt>
                    <dd className="text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                      {incidentData.related_to_users.length > 0 ? (
                        <ul>
                          {incidentData.related_to_users.map((user) => (
                            <li
                              className="flex items-center py-2 text-sm cursor-pointer text-primaryColor hover:underline transition-all"
                              onClick={() => {
                                navigate(`/reporting/profile/${user.user_id}`);
                              }}
                            >
                              <img
                                src={user.image}
                                alt=""
                                className="w-6 h-6 rounded-full"
                              />
                              <span className="ml-2">{user.name}</span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span className="text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                          No users related
                        </span>
                      )}
                    </dd>
                  </div>
                  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-normal text-gray-500">
                      Incident handler
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                      {incidentData.handler ? (
                        <div className="flex items-center">
                          <img
                            src={incidentData.handler.image}
                            alt=""
                            className="w-6 h-6 rounded-full"
                          />
                          <span
                            className="ml-2 cursor-pointer text-primaryColor hover:underline transition-all"
                            onClick={() => {
                              navigate(
                                `/reporting/profile/${incidentData.handler.user_id}`
                              );
                            }}
                          >
                            {incidentData.handler.name}
                          </span>
                        </div>
                      ) : (
                        <span className="px-2 inline-flex text-xs leading-5 rounded-full bg-yellow-100 text-yellow-800">
                          Unassigned
                        </span>
                      )}
                    </dd>
                  </div>
                  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                    <dt className="text-sm font-normal text-gray-500">
                      Incident date
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                      {moment(incidentData.incident_date).format(
                        "MMMM Do YYYY"
                      )}
                    </dd>
                  </div>
                  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-normal text-gray-500">
                      Submission date
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                      {moment(incidentData.submission_date).format(
                        "MMMM Do YYYY"
                      )}
                    </dd>
                  </div>
                  {(incidentData.status === "in_progress" ||
                    incidentData.status === "resolved") && (
                    <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-normal text-gray-500">
                        Work started date
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                        {moment(incidentData.work_started_date).format(
                          "MMMM Do YYYY"
                        )}
                      </dd>
                    </div>
                  )}
                  {incidentData.status === "resolved" && (
                    <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-normal text-gray-500">
                        Resolved date
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                        {moment(incidentData.resolved_date).format(
                          "MMMM Do YYYY"
                        )}
                      </dd>
                    </div>
                  )}

                  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-normal text-gray-500">
                      Status
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                      {incidentData.status === "open" && (
                        <span className="px-2 inline-flex text-xs leading-5 rounded-full items-center bg-yellow-100 text-yellow-800">
                          Open
                        </span>
                      )}
                      {incidentData.status === "resolved" && (
                        <span className="px-2 inline-flex text-xs leading-5  rounded-full items-center bg-green-100 text-green-700">
                          <CheckCircleIcon
                            className="-ml-1 mr-1 h-4 w-4"
                            aria-hidden="true"
                          />
                          Resolved
                        </span>
                      )}
                      {incidentData.status === "in_progress" && (
                        <span className="px-2 inline-flex text-xs leading-5  rounded-full items-center bg-blue-100 text-blue-700">
                          <ClockIcon
                            className="-ml-1 mr-1 h-4 w-4"
                            aria-hidden="true"
                          />
                          In progress
                        </span>
                      )}
                    </dd>
                  </div>

                  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-normal text-gray-500">
                      Urgency
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0">
                      {incidentData.urgency === "low" && (
                        <span className="px-2 inline-flex text-xs leading-5 rounded-full items-center bg-yellow-100 text-yellow-600">
                          <ExclamationCircleIcon
                            className="-ml-1 mr-1 h-4 w-4"
                            aria-hidden="true"
                          />
                          Non-critical
                        </span>
                      )}
                      {incidentData.urgency === "medium" && (
                        <span className="px-2 inline-flex text-xs leading-5 rounded-full items-center bg-orange-100 text-orange-800">
                          <ExclamationCircleIcon
                            className="-ml-1 mr-1 h-4 w-4"
                            aria-hidden="true"
                          />
                          Important
                        </span>
                      )}
                      {incidentData.urgency === "high" && (
                        <span className="px-2 inline-flex text-xs leading-5 rounded-full items-center bg-red-100 text-red-800">
                          <ExclamationCircleIcon
                            className="-ml-1 mr-1 h-4 w-4"
                            aria-hidden="true"
                          />
                          Urgent
                        </span>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="overflow-hidden grow bg-white border border-gray-200 shadow-sm rounded-lg">
                <div className="px-2 py-4 sm:px-6">
                  <div className="flex items-center">
                    <ExclamationTriangleIcon
                      className="h-8 w-8 text-gray-900"
                      aria-hidden="true"
                    />

                    <div className="ml-4">
                      <h3 className="text-md font-semibold text-gray-900">
                        Incident
                      </h3>
                      <p className="max-w-2xl text-sm text-gray-500">
                        Incident description
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border-t px-6 py-4 border-gray-200">
                  <h3 className="text-lg font-semibold">
                    {incidentData.subject}
                  </h3>
                  {incidentData.note ? (
                    <p className="mt-2 text-md leading-6 text-gray-900">
                      {incidentData.note}
                    </p>
                  ) : (
                    <p className="mt-2 text-sm leading-6 text-gray-900">
                      No note
                    </p>
                  )}
                </div>
              </div>
              {incidentData.status === "resolved" && (
                <div className="overflow-hidden mt-6 grow bg-white border border-gray-200 shadow-sm rounded-lg">
                  <div className="px-2 py-4 sm:px-6">
                    <div className="flex items-center">
                      <CheckCircleIcon
                        className="h-8 w-8 text-gray-900"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <h3 className="text-md font-semibold text-gray-900">
                          Resolution
                        </h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                          How the incident was resolved
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200">
                    <p className="px-6 py-4 text-md leading-6 text-gray-900">
                      {incidentData.resolution}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {incidentData.status === "open" && (
              <div className="overflow-hidden relative flex justify-between items-center bg-white border col-span-2 border-gray-200 shadow-sm rounded-lg">
                <HeroPattern />
                <div className="px-4 py-4 sm:px-6">
                  <h3 className="text-md font-semibold leading-7 text-gray-900">
                    Start working on the incident
                  </h3>
                  <p className="max-w-2xl text-sm text-gray-700">
                    Start working on the incident and notify the user that this
                    incident is being worked on.
                  </p>
                </div>
                <button
                  onClick={() => {
                    setStartWorkModalOpen(true);
                  }}
                  className="mx-4 z-50 justify-center content-center items-center hover:scale-105 hover:shadow-md px-4 py-2 text-md font-normal leading-5 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md hover:bg-primaryGreen-600 focus:outline-none focus:border-primaryGreen-700 focus:shadow-outline-primaryGreen active:bg-primaryGreen-700"
                >
                  Start working
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default IncidentPage;
