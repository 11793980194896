import axios from "axios";
axios.defaults.withCredentials = true;

/*                                */
/*      General API routes        */
/*                                */

// Get logged in user data from server

export const getUser = async () => {
  const url = "/api/user/current";
  return await axios.get(url).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get workspace data from server

export const getWorkspace = async () => {
  const url = "/api/workspace";
  return await axios.get(url).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get paginated workspace members from server

export const getPaginatedWorkspaceMembers = async (
  limit,
  skip,
  search,
  sort
) => {
  const url = `/api/workspace/users`;
  return await axios
    .post(url, { limit: limit, skip: skip, search: search, sort: sort })
    .then((res, err) => {
      if (res) {
        return res.data;
      } else if (err) {
        return null;
      }
    });
};

// Update user role on server

export const updateUserRoles = async (
  action,
  userId,
  responsibleCategories
) => {
  const url = `/api/user/update`;
  return await axios
    .post(url, {
      action: action,
      user_id: userId,
      responsible_categories: responsibleCategories,
    })
    .then((res) => {
      return res.data;
    });
};

// Get dictionary data from server

export const getDictionary = async () => {
  const url = "/api/dictionary";
  return await axios.get(url).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Add word to dictionary on server

export const addWord = async (wordData) => {
  const url = "/api/dictionary/add";
  return await axios.post(url, { wordData: wordData }).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Edit word in dictionary on server

export const editWord = async (wordData) => {
  const url = "/api/dictionary/edit";
  return await axios.post(url, { wordData: wordData }).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

/*                                */
/*      Reporting routes           */
/*                                */

export const getReportingData = async (startDate, endDate) => {
  const url = "/api/workspace/reporting";
  return await axios
    .post(url, { start_date: startDate, end_date: endDate })
    .then((res, err) => {
      if (res) {
        return res.data;
      } else if (err) {
        return null;
      }
    });
};

export const getYearlyOccurances = async (year) => {
  const url = "/api/workspace/occurances";
  return await axios.post(url, { year: year }).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

export const getMemberReportingData = async (
  startDate,
  endDate,
  limit,
  skip,
  search,
  sort
) => {
  const url = `/api/workspace/reporting/users`;
  return await axios
    .post(url, {
      start_date: startDate,
      end_date: endDate,
      limit: limit,
      skip: skip,
      search: search,
      sort: sort,
    })
    .then((res) => {
      return res.data;
    });
};

export const getUserById = async (userId, startDate, endDate) => {
  const url = `/api/user/${userId}/reporting`;
  return await axios
    .post(url, { start_date: startDate, end_date: endDate })
    .then((res) => {
      return res.data;
    });
};

export const getUserYearlyOccurances = async (userId, year) => {
  const url = `/api/user/${userId}/occurances`;
  return await axios.post(url, { year: year }).then((res) => {
    return res.data;
  });
};

/*                                */
/*     Incident routes             */
/*                                */

export const getIncidents = async (limit, skip, search, sort) => {
  const url = `/api/incidents`;
  return await axios
    .post(url, { limit: limit, skip: skip, search: search, sort: sort })
    .then((res) => {
      return res.data;
    });
};

export const getIncidentById = async (incidentId) => {
  const url = `/api/incident/${incidentId}`;
  return await axios.get(url).then((res) => {
    return res.data;
  });
};

export const resolveIncident = async (incidentId, resolution) => {
  const url = `/api/incident/resolve`;
  return await axios
    .post(url, { incident_id: incidentId, resolution })
    .then((res) => {
      return res.data;
    });
};

export const updateIncident = async (incidentId, updateType, notification) => {
  const url = `/api/incident/update`;
  return await axios
    .post(url, {
      incident_id: incidentId,
      update_type: "START_WORK",
      notification,
    })
    .then((res) => {
      return res.data;
    });
};

// Send questionnaire data to backend

export const sendQuestionnaire = async (questionnaireData) => {
  const url = `/api/questionnaire`;
  return await axios
    .post(url, { questionnaire_data: questionnaireData })
    .then((res) => {
      return res.data;
    });
};
