import React from "react";

const NotInstalled = () => {
  const handleInstallClick = () => {
    const installUrl = process.env.REACT_APP_INSTALL_URL;
    window.open(installUrl, "_self");
  };
  return (
    <>
      <div className="flex h-full  bg-gradient-to-b from-white via-primaryColor/20 to-primaryColor/40 text-black">
        <div className="flex flex-1 flex-col h-full justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto flex flex-col w-full max-w-sm h-full">
            <div className="flex flex-col grow justify-items-center justify-center">
              <div id="image-container">
                <div className="flex items-center space-x-4">
                  <img
                    className="h-16 w-auto shadow-lg border border-primaryColor/50 rounded-xl"
                    src={process.env.PUBLIC_URL + "/favicon.png"}
                    alt="Dara"
                  />
                  <div className="text-5xl font-rubik font-semibold text-black">
                    Dara
                  </div>
                </div>
                <h2 className="mt-8 text-3xl font-rubik font-semibold text-gray-900">
                  Whoops.. Dara is not installed or you are not an admin
                </h2>
              </div>
              <p className="font-normal text-black mt-4">
                Either Dara is not installed to this Slack workspace or you are
                not an admin. Please start your freemium susbscription to access
                Dara or contact your Dara adminstrator.
              </p>
              <div className="flex-col items-center space-y-4 mt-8">
                <div
                  className="bg-primaryColor text-white px-6 py-4 rounded-lg justify-center hover:scale-110 transition-all duration-500 flex align-middle items-center font-semibold text-xl cursor-pointer"
                  onClick={() => handleInstallClick()}
                >
                  <img
                    className="h-8 w-8 mr-3"
                    src={process.env.PUBLIC_URL + "/slack-logo.svg"}
                    alt="Slack"
                  />
                  <p>Add to Slack</p>
                </div>
              </div>
              <p className="text-gray-700 mt-8 text-sm text-center">
                By signing in, you agree to our{" "}
                <a
                  href="https://www.darabot.com/terms-of-service"
                  target="_blank"
                  className="hover:underline cursor-pointer"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.darabot.com/privacy-policy"
                  target="_blank"
                  className="hover:underline cursor-pointer"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
            <a
              href="https://www.darabot.com/contact"
              className="mt-6 text-gray-700 text-center text-xs flex-shrink-0 hover:underline hover:text-primaryGreen cursor-pointer"
            >
              Trouble signing in?
            </a>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover object-right-bottom"
            src={process.env.PUBLIC_URL + "/diversity.jpg"}
            alt="Diversity"
          />
        </div>
      </div>
    </>
  );
};

export default NotInstalled;
