const Education = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div className="relative mt-8 mb-10">
        <div className="relative">
          <h1 className="text-2xl text-primaryColor font-semibold font-rubik mb-1">
            Education
          </h1>
          <p className="text-gray-600 text-md">
            Educate your organization on DEI-issues.
          </p>
        </div>
      </div>
      <div className="mx-auto max-w-2xl sm:text-center animate-fade">
        <p className="mt-2 text-3xl font-semibold tracking-tight text-primaryColor sm:text-4xl">
          Coming soon..{" "}
        </p>
        <p className="mt-4 text-lg leading-8 text-gray-600">
          We are currently working on this feature. ✌️ Please check back later.
        </p>
      </div>
      <div className="relative overflow-hidden blur-sm pt-16 animate-fade">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            src="https://tailwindui.com/img/component-images/project-app-screenshot.png"
            alt="App screenshot"
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            width={2432}
            height={1442}
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-50 pt-[7%]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
