import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  ChevronUpDownIcon,
  CheckIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";
import { SmalLoadingIndicator } from "../Helpers/LoadingComponents";
import moment from "moment";

const monthsArray = [
  {
    value: 1,
    name: "Jan",
  },
  {
    value: 2,
    name: "Feb",
  },
  {
    value: 3,
    name: "Mar",
  },
  {
    value: 4,
    name: "Apr",
  },
  {
    value: 5,
    name: "May",
  },
  {
    value: 6,
    name: "Jun",
  },
  {
    value: 7,
    name: "July",
  },
  {
    value: 8,
    name: "Aug",
  },
  {
    value: 9,
    name: "Sep",
  },
  {
    value: 10,
    name: "Oct",
  },
  {
    value: 11,
    name: "Nov",
  },
  {
    value: 12,
    name: "Dec",
  },
];

const OccurancesChart = ({
  yearlyData,
  getYearlyOccurances,
  isLoading,
  creationDate,
}) => {
  yearlyData = yearlyData || [];

  const creationDateMoment = moment(creationDate);
  const diff = moment().year() - moment(creationDateMoment).year() + 1;
  const years = [...Array(diff).keys()].map(
    (i) => i + moment(creationDateMoment).year()
  );

  const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);

  useEffect(() => {
    getYearlyOccurances(selectedYear);
  }, [selectedYear]);

  // Loop through the yearlyData array and create a new array of objects with the following format for each object: {name: "January", amount: 0}
  const data = monthsArray.map((month) => {
    const monthData = yearlyData.find((data) => data._id.month === month.value);
    return {
      name: month.name,
      count: monthData ? monthData.count : 0,
    };
  });

  return (
    <div className="w-full min-h-full bg-white/20 col-span-2 rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
      <div className="relative px-6 py-6 justify-between flex items-center">
        <div className="flex items-center space-x-3">
          <ChartBarIcon className="w-8 h-8" />
          <div className="flex flex-col">
            <h3 className="text-lg font-medium text-gray-900">Yearly chart</h3>
            <p className="text-sm text-gray-500">
              Occurances per month for the selected year
            </p>
          </div>
        </div>
        <Listbox
          value={selectedYear}
          onChange={setSelectedYear}
          className="w-36"
        >
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-green-300 border border-gray-200 focus-visible:ring-offset-2 focus-visible:border-green-500 sm:text-sm">
              <span className="block truncate">{selectedYear}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute w-36 z-10 py-1 mt-1 cursor-pointer overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {years.map((year) => (
                  <Listbox.Option
                    key={year}
                    className={({ active }) =>
                      `cursor-default select-none relative py-2 pl-10 pr-4 ${
                        active
                          ? "text-primaryColor bg-primaryColor/10"
                          : "text-gray-900"
                      }`
                    }
                    value={year}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {year}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primaryColor">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className="flex items-center border-t border-gray-200 justify-between px-8 pt-8"></div>
      {isLoading && (
        <div className="flex items-center justify-center h-[300px]">
          <SmalLoadingIndicator />
        </div>
      )}
      {!isLoading && (
        <ResponsiveContainer width="95%" height={300}>
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 30,
              right: 10,
              left: 10,
              bottom: 15,
            }}
          >
            <defs>
              <linearGradient id="colorAll" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#BA7C7C" stopOpacity={0.5} />
                <stop offset="95%" stopColor="#ffa8a8" stopOpacity={0.25} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="name"
              tick={{ fontSize: 11 }}
              interval={0}
              stroke="#6c6c6c"
            />
            <YAxis tick={{ fontSize: 11 }} stroke="#6c6c6c" />
            <Tooltip
              wrapperStyle={{
                border: "1px solid rgba(229, 231, 235, 1)",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "0.5rem",
                fontSize: "0.9rem",
                outline: "none",
              }}
              contentStyle={{
                border: "none",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                outline: "none",
              }}
              labelStyle={{
                fontWeight: "normal",
              }}
            />
            <Area
              type="monotone"
              name="Count"
              fillOpacity={1}
              fill="url(#colorAll)"
              dataKey="count"
              stroke="#BA7C7C"
              activeDot={{ r: 8 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default OccurancesChart;
