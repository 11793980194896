import { useTable, usePagination, useSortBy } from "react-table";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MinusSmallIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Table = ({
  columns,
  data,
  pageCount: controlledPageCount,
  navigate,
  fetchData,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      pageCount: controlledPageCount,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ["user_id", "user_image", "user_title"],
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    fetchData && fetchData(pageIndex, pageSize, sortBy);
  }, [pageIndex, pageSize, sortBy]);

  return (
    <>
      <div className="flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle  sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table
                {...getTableProps()}
                className="min-w-full divide-y rounded-lg divide-gray-200"
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="divide-x divide-gray-200"
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-6 py-3.5 text-left text-sm font-semibold"
                        >
                          <div className="group inline-flex">
                            {column.render("Header")}
                            {!column.isSorted && (
                              <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                <MinusSmallIcon
                                  className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronUpIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ))}
                      <th className="px-6 py-3.5 text-left text-sm font-medium text-gray-800"></th>
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-20"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const picture_uri = row.values.user_image;
                    const userId = row.values.user_id;
                    const user_title = row.values.user_title;
                    return (
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                              key={i}
                            >
                              <div className="text-gray-700 flex items-center">
                                {cell.column.id === "user_name" ? (
                                  <div className="h-10 w-10 flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={picture_uri}
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                                <div className="ml-4 cursor-pointer">
                                  <span
                                    className={
                                      cell.column.id === "user_name"
                                        ? "font-semibold cursor-pointer"
                                        : ""
                                    }
                                    onClick={() => {
                                      if (cell.column.id === "user_name") {
                                        navigate(
                                          "/reporting/profile/" + userId
                                        );
                                      }
                                    }}
                                  >
                                    {cell.render("Cell")}
                                  </span>
                                  {cell.column.id === "user_name" ? (
                                    <span className="text-gray-500 block text-xs">
                                      {user_title}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </td>
                          );
                        })}
                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <div
                            onClick={() => {
                              navigate("/reporting/profile/" + userId);
                            }}
                            className="relative w-8 float-right cursor-pointer hover:text-primaryColor transition-all after:absolute after:bg-primaryColor after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300"
                          >
                            View
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="py-3 px-3 flex items-center justify-between rounded-lg mt-4">
              <div className="ml-2 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                  </p>
                </div>
                <div className="ml-10 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <select
                    className="relative py-2 pl-3 pr-10 text-left bg-white rounded-lg border-none shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 sm:text-xs"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 30].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize} per page
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md border border-gray-200 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>

                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md border border-gray-200 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PeopleTable = (props) => {
  const { startDate, endDate, getReportingMemberData, memberData, isLoading } =
    props;

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [pageIndexAndSize, setPageIndexAndSize] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: null,
  });

  /* Handle search with debounce */
  const handleSearch = _.debounce(
    (search) => {
      setSearchTerm(search);
    },
    1000,
    {
      maxWait: 1000,
    }
  );

  const fetchData = useCallback((pageIndex, pageSize, sortBy) => {
    setPageIndexAndSize({ pageIndex, pageSize, sortBy });
    getMembersByDateRange(pageSize, pageSize * pageIndex, searchTerm, sortBy);
  });

  const getMembersByDateRange = async (limit, skip, search, sort) => {
    await getReportingMemberData(limit, skip, search, sort);
  };

  useEffect(() => {
    getMembersByDateRange(
      pageIndexAndSize.pageSize,
      pageIndexAndSize.pageSize * pageIndexAndSize.pageIndex,
      searchTerm,
      pageIndexAndSize.sortBy
    );
  }, [startDate]);

  useEffect(() => {
    getMembersByDateRange(
      pageIndexAndSize.pageSize,
      pageIndexAndSize.pageSize * pageIndexAndSize.pageIndex,
      searchTerm,
      pageIndexAndSize.sortBy
    );
  }, [endDate]);

  useEffect(() => {
    getMembersByDateRange(10, 0, searchTerm, pageIndexAndSize.sortBy);
  }, [searchTerm]);

  useEffect(() => {
    if (memberData) {
      setPageCount(memberData.paging.pages);
    }
  }, [memberData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "user_id",
      },
      {
        Header: "Picture",
        accessor: "user_image",
      },
      {
        Header: "Name",
        accessor: "user_name",
      },
      {
        Header: "Title",
        accessor: "user_title",
      },
      {
        Header: "Non-inclusive words used",
        accessor: "word_count",
      },
      {
        Header: "Related to DEI-incidents",
        accessor: "incident_count",
      },
    ],
    []
  );

  const tableData = memberData ? memberData.data : [];

  return (
    <div className="w-full min-h-full mt-8 bg-white col-span-2 rounded-lg animate-enter shadow-sm transition-all border border-gray-200">
      <div className="relative px-6 py-6 justify-between flex items-center">
        <div className="flex items-center space-x-3">
          <UserCircleIcon className="w-8 h-8" />
          <div className="flex flex-col">
            <h3 className="text-lg font-medium text-gray-900">
              People analytics
            </h3>
            <p className="text-sm text-gray-500">
              Click view to see more details for a specific person
            </p>
          </div>
        </div>
        <div>
          <div className="px-4">
            <h5 className="text-sm font-medium text-black leading-relaxed">
              Search
            </h5>
            <input
              id="search"
              type="text"
              placeholder="Search by name.."
              className="w-full text-base md:text-sm border transition-all duration-300 border-gray-200 bg-white focus:border-transparent rounded-md shadow form-input focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-primaryColor"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200">
        <Table
          columns={columns}
          data={tableData}
          navigate={navigate}
          fetchData={fetchData}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
};

export default PeopleTable;
