import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as api from "../../services/api";
import { SmalLoadingIndicator } from "../Helpers/LoadingComponents";

const StartWorkModal = (props) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState("");

  const closeModal = () => {
    setSuccess(false);
    props.setOpen(false);
  };

  const startWork = async () => {
    setLoading(true);
    const res = await api.updateIncident(
      props.incident._id,
      "START_WORK",
      notification
    );

    if (res) {
      setLoading(false);
      setSuccess(true);
    } else {
      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-[2px]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {!success && !loading && (
                  <Dialog.Title
                    as="div"
                    className="text-lg font-medium items-center flex space-x-3 text-"
                  >
                    <div className="flex items-center justify-center h-8 w-8 rounded-lg bg-primaryColor border border-primaryColor bg-opacity-10">
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-primaryColor"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="text-primaryColor">
                      Start working on the incident
                    </div>
                  </Dialog.Title>
                )}
                {!success && !loading && (
                  <>
                    <p className="text-sm mt-3 text-gray-700">
                      Are you sure you want to start working on this incident?{" "}
                      {props.incident.reporter.name} will be notified that the
                      incident is in progress. Please enter a notification
                      message below.
                    </p>
                    <form>
                      <div className="mt-4">
                        <label
                          htmlFor="resolution"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Notification message
                        </label>
                        <div className="mt-2">
                          <textarea
                            id="notification"
                            name="notification"
                            placeholder="Enter notification message.."
                            rows={3}
                            className="shadow-sm focus:ring-primaryColor transition-all focus:border-primaryColor block w-full sm:text-sm border-gray-300 rounded-md"
                            defaultValue={""}
                            onChange={(e) => setNotification(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </form>
                    <div className="mt-6 space-x-3 flex items-center justify-end">
                      <button
                        type="button"
                        className="focus:outline-none inline-flex  float-right justify-center rounded-lg border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-grey-900 hover:bg-grey-200 focus-visible:ring-2 focus-visible:ring-grey-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-green-600 transition-all hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor"
                        onClick={startWork}
                      >
                        Start working on incident
                      </button>
                    </div>
                  </>
                )}
                {loading && (
                  <div className="flex items-center w-full h-48 justify-center animate-enter">
                    <SmalLoadingIndicator />
                  </div>
                )}
                {success && (
                  <>
                    <div className="flex items-center mt-8 justify-center animate-enter">
                      <ExclamationTriangleIcon
                        className="h-24 w-24 text-green-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-1 text-center items-center content-center flex flex-col">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-medium text-green-500"
                      >
                        Work started
                      </Dialog.Title>
                      <div className="mt-2 max-w-sm">
                        <p className="text-sm text-gray-500">
                          <b>{props.incident.reporter.name}</b> has been
                          notified that you have started working on the
                          incident.
                        </p>
                      </div>
                    </div>
                    <div className="mt-6 space-x-3 flex items-center justify-end">
                      <button
                        type="button"
                        className="focus:outline-none inline-flex  float-right justify-center rounded-lg border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-grey-900 hover:bg-grey-200 focus-visible:ring-2 focus-visible:ring-grey-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default StartWorkModal;
